export const METRICS_ENDPOINT = '/ts-metrics/';
export const METRICS_DURATION_QUEUE = 10000;
export const METRICS_AD_COUNT = 'horoscopes_ad_counter';

export const METRICS_NAMES_LIST = [
  'CLS',
  'FCP',
  'FID',
  'LCP',
  'TTFB',
  'TBT',
  METRICS_AD_COUNT,
] as const;

export interface MetricsNamesType {
  name: (typeof METRICS_NAMES_LIST)[number];
  value: Record<string, string | number>;
}
