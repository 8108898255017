/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react';

import { Ad } from 'common/components/Ad';

export const TEXT_RANGE_BEFORE_TOGGLE = 500;
export const TEXT_COMPATIBILITY_FORM_SYMBOLS_COUNT = 600;
export const ONBOARDING_EXPIRES = 365 * 10; // expires in 3650 days
export const STORIES_ANIMATION_EXPIRES = 14; // expires in 14 days

const orderedDesktopAd = ['center', 'inread'];
const orderedMobileAd = [
  'content1',
  'inpage_m',
  'content_spec',
  'content2',
  'content3',
  'content4',
];

const textBreakPointDesktop = 800;
const textBreakPointMobile = 750;

const additionalAdsBreakPointDesktop = 2400;
const additionalAdsBreakPointMobile = 1500;

export const getOrderedAds = (isMobile: boolean) =>
  isMobile ? orderedMobileAd : orderedDesktopAd;

export const getTextBreakPoint = (isMobile: boolean) =>
  isMobile ? textBreakPointMobile : textBreakPointDesktop;

export const getAdditionalAdsBreakPoint = (isMobile: boolean) =>
  isMobile ? additionalAdsBreakPointMobile : additionalAdsBreakPointDesktop;

type BannerIndexes = Record<number, string>;

export const getAdForInnerBlocks = ({
  bannerIndexes,
  isMobile,
  index,
  length,
  rule,
  ignoreEnd,
}: {
  bannerIndexes?: BannerIndexes;
  isMobile: boolean;
  index: number;
  length: number;
  rule?: boolean;
  ignoreEnd?: boolean;
}) => {
  const banners: BannerIndexes =
    bannerIndexes ||
    (isMobile
      ? {
          1: 'content1',
          4: 'inpage_m',
          7: 'content_spec',
          10: 'content2',
          13: 'content3',
        }
      : {
          0: 'center',
          3: 'inread',
        });

  let bannerName = banners[index];

  // в мобиле с 16 (включительно) и каждые 3 блока
  if (isMobile && (rule ?? (index >= 16 && (index - 16) % 3 === 0))) {
    bannerName = 'content4';
    // в мобиле с 7 (включительно) и каждые 9 блоков
  } else if (!isMobile && (rule ?? (index >= 6 && (index - 6) % 9 === 0))) {
    bannerName = 'inread';
  }

  // Если не нашли баннер или он слишком близко к концу (кроме случая специального показа)
  if (!ignoreEnd && (!bannerName || index + 1 >= length - 2)) return null;

  const onRenderWrapperStyle = {
    marginTop: isMobile ? 20 : 30,
    marginBottom: isMobile ? 0 : 10,
  };

  return <Ad name={bannerName} onRenderWrapperStyle={onRenderWrapperStyle} />;
};
