export const SPLIT_DEFAULT = 'default_ts';

export const SPLIT_VALUE_KEY = '__value__';

// https://jira.rambler-co.ru/browse/HORO-5906
export const EMO_SPLIT_AS_IS = 50;
export const EMO_SPLIT_TEST_1 = 51;
export const EMO_SPLIT_TEST_2 = 52;
export const EMO_SPLIT_TEST_3 = 53;

export const EMO_SPLIT_NAME = 'emo_widget';

export const EMO_SPLIT_VALUE_NAME: Record<number, string> = {
  50: 'as_is',
  51: 'test1',
  52: 'test2',
  53: 'test3',
};
