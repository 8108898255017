export const SOCIALS_SVG: Record<SocialType, string> = {
  link: 'link',
  linkedin: 'linkedin',
  livejournal: 'livejournal',
  mail: 'mail',
  moimir: 'mailru',
  vkontakte: 'vk',
  odnoklassniki: 'ok',
  instagram: 'instagram',
};
