const LAST_MOON_DAY = 30;
const LAST_WAXING_MOON_DAY = 15;
const FOOL_MOON_DAY = 16;
const FIRST_WANING_MOON_DAY = 17;
const LAST_WANING_MOON_DAY = 29;

export enum MoonPhases {
  new_moon = 1,
  waxing_moon = 2,
  full_moon = 16,
  waning_moon = 17,
}

export const MOON_TYPES: Record<MoonPhases, string> = {
  [MoonPhases.new_moon]: 'новолуние',
  [MoonPhases.waxing_moon]: 'растущая',
  [MoonPhases.full_moon]: 'полнолуние',
  [MoonPhases.waning_moon]: 'убывающая',
};

export const getMoonType = (day: number) => {
  let moonType = '';

  if (day === 1 || day === LAST_MOON_DAY) {
    moonType = MOON_TYPES[MoonPhases.new_moon];
  } else if (day >= 2 && day <= LAST_WAXING_MOON_DAY) {
    moonType = MOON_TYPES[MoonPhases.waxing_moon];
  } else if (day === FOOL_MOON_DAY) {
    moonType = MOON_TYPES[MoonPhases.full_moon];
  } else if (day >= FIRST_WANING_MOON_DAY && day <= LAST_WANING_MOON_DAY) {
    moonType = MOON_TYPES[MoonPhases.waning_moon];
  }

  return moonType;
};
