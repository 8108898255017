const desktopCardsWeight: Record<ICardProps['type'] | 'detail', number> = {
  widget: 60,
  widget_bubbles: 80,
  widget_bubbles_names: 100,
  widget_with_inner_blocks: 100,
  widget_bubbles_with_inner_blocks: 90,
  widget_bubbles_with_inner_blocks_calendar: 100,
  list_view_alphabet: 100,
  widgets_with_inner_blocks_sub: 0,
  sign_with_inner_blocks_sub: 0,
  sign_widget_bubbles_with_inner_blocks_sub: 0,
  sign_widget: 60,
  sign_widget_bubbles: 80,
  sign_widget_with_inner_blocks: 60,
  sign_widget_bubbles_with_inner_blocks: 60,
  form: 25,
  video: 50,
  mini: 10,
  stories: 25,
  longread: 45,
  longread_detail: 100,
  dreams_alphabet_index: 100,
  dreams_alphabet_index_sub: 0,
  bubbles: 35,
  fortune_preview: 45,
  fortune_coffee: 100,
  horoscopes_special: 100,
  horoscopes_special_bubbles: 100,
  two_column_group: 100,
  two_column_group_sub: 0,
  about: 60,
  handing_title: 5,
  dashboard: 80,
  humandesign: 60,
  humandesign_experimental: 60,
  static: 60,
  // На случай если нужно будет после главной карточки на детальной странице сразу ставить billboard
  detail: 0,
};

const mobileCardsWeight: Record<ICardProps['type'] | 'detail', number> = {
  widget: 85,
  widget_bubbles: 100,
  widget_bubbles_names: 100,
  widget_with_inner_blocks: 100,
  widget_bubbles_with_inner_blocks: 100,
  widget_bubbles_with_inner_blocks_calendar: 100,
  list_view_alphabet: 100,
  widgets_with_inner_blocks_sub: 0,
  sign_with_inner_blocks_sub: 0,
  sign_widget_bubbles_with_inner_blocks_sub: 0,
  sign_widget: 85,
  sign_widget_bubbles: 100,
  sign_widget_with_inner_blocks: 85,
  sign_widget_bubbles_with_inner_blocks: 85,
  form: 35,
  video: 50,
  mini: 10,
  stories: 30,
  longread: 50,
  longread_detail: 100,
  dreams_alphabet_index: 100,
  dreams_alphabet_index_sub: 0,
  bubbles: 50,
  fortune_preview: 50,
  fortune_coffee: 100,
  horoscopes_special: 100,
  horoscopes_special_bubbles: 100,
  two_column_group: 100,
  two_column_group_sub: 0,
  about: 100,
  handing_title: 5,
  dashboard: 85,
  humandesign: 60,
  humandesign_experimental: 60,
  static: 85,
  // Для детальной страницы нужно после основной карточки всегда начинать с top_banner
  detail: 0,
};

export const getCardWeight = (
  cardType: ICardProps['type'] | 'detail' | undefined,
  isMobile: boolean,
) => {
  if (typeof cardType === 'undefined') {
    console.error(
      `${new Date()} невозможно определить "вес" карточки так как отсутствует её тип`,
    );

    return 0;
  }

  const weightsData = isMobile ? mobileCardsWeight : desktopCardsWeight;

  const weight = weightsData[cardType];

  if (typeof weight === 'undefined') {
    console.error(`${new Date()} отсутствует "вес" карточки ${cardType}`);

    return 0;
  }

  return weight;
};
