export const desktopSlots = {
  showUp: {
    id: 579503430,
    needInTWA: false,
  },
  fullscreen: {
    id: 432194426,
    needInTWA: false,
  },
  stickyLine: {
    id: 579336619,
    needInTWA: false,
  },
  topline_banner: {
    id: 542324967,
    needInTWA: false,
  },
  billboard: {
    id: 432193310,
    withPlaceholder: true,
    needInTWA: false,
  },
  Promovidzhet: {
    id: 579498242,
    withPlaceholder: true,
    needInTWA: false,
  },
  ban_300x600: {
    id: 432185708,
    withPlaceholder: true,
    needInTWA: false,
  },
  ban_300x600_2: {
    id: 432186246,
    isLazy: true,
    withPlaceholder: true,
    needInTWA: false,
  },
  superfooter: {
    id: 432185684,
    isLazy: true,
    withPlaceholder: true,
    needInTWA: false,
  },
  parallax: {
    id: 446877576,
    isLazy: true,
    withPlaceholder: true,
    needInTWA: false,
  },
  sponsored: {
    id: 446877580,
    needInTWA: false,
  },
  center: {
    id: 446877568,
    isLazy: true,
    withPlaceholder: true,
    needInTWA: false,
  },
  inread: {
    id: 432194456,
    isLazy: true,
    withPlaceholder: true,
    needInTWA: false,
  },
  // inpage: {
  //   id:              436384662,
  //   isLazy:          true,
  //   withPlaceholder: true,
  //   needInTWA: false,
  // },
  // native1: {
  //   id:              432194472,
  //   isLazy:          true,
  //   withPlaceholder: true,
  //   needInTWA: false,
  // },
  // native2: {
  //   id:              432194474,
  //   isLazy:          true,
  //   withPlaceholder: true,
  //   needInTWA: false,
  // },
  native3: {
    id: 576338663,
    isLazy: true,
    withPlaceholder: true,
    needInTWA: false,
  },
  // context_240x200: {
  //   id:              432189978,
  //   isLazy:          true,
  //   withPlaceholder: true,
  //   needInTWA: false,
  // },
  context: {
    id: 432194412,
    isLazy: true,
    withPlaceholder: true,
    needInTWA: false,
  },
  // marketing_content: {
  //   id:              579354893,
  //   isLazy:          true,
  //   withPlaceholder: true,
  //   needInTWA:       false,
  // },
  // nativeFooter: {
  //   id:              432194556,
  //   isLazy:          true,
  //   withPlaceholder: true,
  //   needInTWA: false,
  // },
};

export const mobileSlots = {
  fullscreen_m: {
    id: 456199866,
    needInTWA: true,
  },
  rich: {
    id: 456199930,
    needInTWA: true,
  },
  top_banner: {
    id: 456199960,
    withPlaceholder: true,
    needInTWA: true,
  },
  Promovidzhetmob: {
    id: 579498244,
    withPlaceholder: true,
    needInTWA: false,
  },
  listing1: {
    id: 456199884,
    isLazy: true,
    withPlaceholder: true,
    needInTWA: false,
  },
  listing2: {
    id: 456199890,
    isLazy: true,
    withPlaceholder: true,
    needInTWA: false,
  },
  listing3: {
    id: 456199894,
    isLazy: true,
    withPlaceholder: true,
    needInTWA: false,
  },
  listing4: {
    id: 456199912,
    isLazy: true,
    withPlaceholder: true,
    needInTWA: false,
  },
  listing_spec: {
    id: 474842478,
    isLazy: true,
    withPlaceholder: true,
    needInTWA: false,
  },
  sponsored_m: {
    id: 456199958,
    needInTWA: false,
  },
  inpage_m: {
    id: 456199870,
    isLazy: true,
    withPlaceholder: true,
    needInTWA: false,
  },
  content1: {
    id: 456199708,
    isLazy: true,
    withPlaceholder: true,
    needInTWA: true,
  },
  content2: {
    id: 456199756,
    isLazy: true,
    withPlaceholder: true,
    needInTWA: true,
  },
  content3: {
    id: 456199760,
    isLazy: true,
    withPlaceholder: true,
    needInTWA: true,
  },
  content4: {
    id: 456199796,
    isLazy: true,
    withPlaceholder: true,
    needInTWA: true,
  },
  content5: {
    id: 579357760,
    isLazy: true,
    withPlaceholder: true,
    needInTWA: false,
  },
  content_spec: {
    id: 474841790,
    isLazy: true,
    withPlaceholder: true,
    needInTWA: false,
  },
  marketing_content_mobile: {
    id: 579354895,
    isLazy: true,
    withPlaceholder: true,
    needInTWA: false,
  },
  footer: {
    id: 456199860,
    isLazy: true,
    withPlaceholder: true,
    needInTWA: false,
  },
};

export const totalBanners = {
  desktop: {
    padID: 432183370,
    slots: desktopSlots,
  },
  mobile: {
    padID: 456199694,
    slots: mobileSlots,
  },
};
