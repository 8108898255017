import { SIGN } from 'config/constants/sign';

export const signDates = [
  {
    sign: SIGN.aries,
    start: '03-21',
    end: '04-20',
  },
  {
    sign: SIGN.taurus,
    start: '04-21',
    end: '05-20',
  },
  {
    sign: SIGN.gemini,
    start: '05-21',
    end: '06-21',
  },
  {
    sign: SIGN.cancer,
    start: '06-22',
    end: '07-22',
  },
  {
    sign: SIGN.leo,
    start: '07-23',
    end: '08-22',
  },
  {
    sign: SIGN.virgo,
    start: '08-23',
    end: '09-23',
  },
  {
    sign: SIGN.libra,
    start: '09-24',
    end: '10-23',
  },
  {
    sign: SIGN.scorpio,
    start: '10-24',
    end: '11-22',
  },
  {
    sign: SIGN.sagittarius,
    start: '11-23',
    end: '12-21',
  },
  {
    sign: SIGN.capricorn,
    start: '12-22',
    end: '12-31',
  },
  {
    sign: SIGN.capricorn, // козерога разделила на 2 объекта, чтоб не мучаться с подстановкой года
    start: '01-01',
    end: '01-20',
  },
  {
    sign: SIGN.aquarius,
    start: '01-21',
    end: '02-18',
  },
  {
    sign: SIGN.pisces,
    start: '02-19',
    end: '03-20',
  },
];
