import path from 'path';
import { totalBanners as BANNERS } from 'config/constants/banners';
import PUSHWOOSH from 'config/constants/pushwoosh';
import * as localConfig from 'config/profiles/local';

const { RELEASE_VERSION = 'local', APP_ENV } = process.env;

let STATS_FILE = '';

try {
  if (APP_ENV !== 'CI') {
    STATS_FILE = path.resolve('./build/loadable-stats.json');
  }
} catch {
  console.log('Ошибка чтения данных из loadable-stats.json'); // eslint-disable-line
}

// Объект локальных настроек для переопределения конфигурации сервера
let SETTINGS_LOCAL = {};

try {
  if (__DEV__) {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    SETTINGS_LOCAL = require('../settings-local.json');
  }
} catch {
  console.log('Ошибка чтения данных из settings-local.json'); // eslint-disable-line
}

const RUN_PROFILE: Config['RUN_PROFILE'] =
  (process.env.RUN_PROFILE as any) || 'local';

let profile: typeof localConfig;

try {
  // eslint-disable-next-line
  profile = { ...require(`./profiles/${process.env.RUN_PROFILE}`) };
} catch {
  if (!__DEV__) {
    console.log('Ошибка чтения конфига для профиля:', process.env.RUN_PROFILE); // eslint-disable-line
    console.log('Будет использован дефолтный профиль (profiles/local)'); // eslint-disable-line
  }

  profile = { ...localConfig };
}

export const config: Config = {
  APP_NAME: `HOROS-${RELEASE_VERSION}`,
  RUN_PROFILE,
  BANNERS,
  PUSHWOOSH,
  RELEASE_VERSION,
  STATS_FILE,
  ...profile,
  ...SETTINGS_LOCAL,
};
