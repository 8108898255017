const DEFAULT_PORT = 3000;

export const PORT = process.env.APP_PORT || DEFAULT_PORT;

export const API_URL = 'https://stage.horos.rambler.ru/api/front/';
export const API_TIMEOUT = 3000;
export const API_TIMEOUT_CLIENT = 10000;

export const API_SUBSCRIPTION = 'https://subscriptions-stage.rambler.ru';

export const API_GEO_LOCATION = 'https://www.rambler.ru/location/current';

export const { CAPTCHA_KEY } = process.env;

export const METRICS = true;

const logObj = {
  port: 514,
  type: 'BSD',
  facility: 'local0',
  localhost: process.env.LOG_INDEX as string,
};
export const SYSLOG = {
  address: process.env.LOG_NODE as string,
  ...logObj,
};
export const LOGLEVEL = '';
export const LOGGING = false;

export const SENTRY_DSN =
  'https://4f714e0240310a72fac5a34261baf9c1@sentry-saas.rambler-co.ru/95';
export const SENTRY_SAMPLE_RATE_SERVER = 1;
export const SENTRY_SAMPLE_RATE_BROWSER = 1;

export const METRICS_SAMPLE_RATE = 1;

export const HTTPS_PROXY = '';
export const HD_REJECT_UNAUTHORIZED = !(
  process.env.HD_REJECT_UNAUTHORIZED &&
  process.env.HD_REJECT_UNAUTHORIZED === 'false'
);

export const NEWS_LETTER_CRM_KEYS = {
  BIORHYTHMS: 'daily_biorhythms_stage',
  TAROT: 'weekly_tarot_stage',
  DAILY_SING: 'daily_sign_horoscopes_stage',
  ARTICLES: 'media_article_stage',
};

export const SPLITS_JSON = `${API_URL}v3/experiments/`;
