import { LinkType, SocialLinkType } from '@rambler-components/footer';
import { setRswitchDesktop, setRswitchMobile } from 'utils/rswitcher';

const createClick = (isMobile: boolean) => () => {
  const switcher = isMobile ? setRswitchDesktop : setRswitchMobile;

  switcher();
};

export const socialLinks = [
  {
    href: 'https://vk.com/rambler',
    code: 'vk',
  },
  {
    href: 'https://ok.ru/rambler',
    code: 'ok',
  },
  {
    href: 'tg://resolve?domain=news_rambler',
    code: 'tg',
  },
  {
    href: 'https://news.rambler.ru/rss/',
    code: 'rss',
  },
];

export const createSimpleLinks = (isMobile = false): LinkType[] => [
  {
    label: isMobile ? 'Полная версия' : 'Мобильная версия',
    onClick: createClick(isMobile),
  },
  {
    label: 'Реклама',
    url: 'https://reklama.rambler.ru/sites/horoscopes/?about=1',
  },
  {
    label: 'Редакционная политика',
    url: 'https://help.rambler.ru/news/redakcionnaya_politika/1853/',
  },
  {
    label: 'Условия использования',
    url: 'https://help.rambler.ru/legal/1430/',
  },
  {
    label: 'Политика конфиденциальности',
    url: 'https://help.rambler.ru/legal/1142/',
  },
  {
    label: 'О проекте',
    url: '/editors/',
  },
  {
    url: 'https://help.rambler.ru/feedback/horoscopes/',
    label: 'Помощь',
  },
];

export const socialFooterLinks: SocialLinkType[] = [
  {
    url: 'https://vk.com/rambler',
    code: 'vk',
  },
  {
    url: 'https://ok.ru/rambler',
    code: 'ok',
  },
  {
    url: 'tg://resolve?domain=news_rambler',
    code: 'tg',
  },
];
