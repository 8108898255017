import React from 'react';
import { Link } from 'react-router-dom';

export const TOOLTIP_STORAGE_VALUE = 'cardTooltipShowed';
export const TOOLTIP_TIME = 5000;

export const TAROT_FORECAST_YEARS = '2024';

export enum FortuneTypes {
  future = 'future',
  love = 'love',
  short = 'short',
  birthdate = 'birthdate',
  keltskiyKrest = 'keltskiyKrest',
}

export const FORTUNE_LINKS_BY_TYPES = {
  [FortuneTypes.future]: '/taro/budushchee/',
  [FortuneTypes.love]: '/taro/lyubov/',
  [FortuneTypes.short]: '/taro/da-net/',
  [FortuneTypes.birthdate]: '/taro/data-rozhdeniya/',
  [FortuneTypes.keltskiyKrest]: '/gadanie-online/taro-keltskiy-krest/',
};

export const FORTUNE_TITLES_BY_TYPES = {
  [FortuneTypes.future]: 'Онлайн-гадание таро на будущее',
  [FortuneTypes.love]: 'Онлайн-гадание таро на любовь',
  [FortuneTypes.short]: 'Гадание да/нет',
  [FortuneTypes.birthdate]: 'Прогноз-гадание таро по дате рождения',
  [FortuneTypes.keltskiyKrest]: 'Онлайн-гадание таро «Кельтский крест»',
};

export const FORTUNES = [
  FortuneTypes.love,
  FortuneTypes.future,
  FortuneTypes.short,
  FortuneTypes.birthdate,
  FortuneTypes.keltskiyKrest,
];

export const CARD_NUMBERS_BY_TYPE = {
  [FortuneTypes.future]: 10,
  [FortuneTypes.love]: 6,
  [FortuneTypes.short]: 15,
  [FortuneTypes.birthdate]: 8,
  [FortuneTypes.keltskiyKrest]: 1,
};

export const CODENAMES_BY_TYPE = {
  [FortuneTypes.future]: 'tarot_future',
  [FortuneTypes.love]: 'tarot_love',
  [FortuneTypes.short]: 'tarot_yes_no',
  [FortuneTypes.birthdate]: 'tarot_birthdate',
  [FortuneTypes.keltskiyKrest]: 'celtic_cross',
};

enum Value {
  One,
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
  Eight,
  Nine,
  Ten,
  Page,
  Knight,
  Queen,
  King,
}

export enum Category {
  Sticks,
  Cups,
  Swords,
  Pentacles,
}

const synonymsByValue = {
  [Value.One]: ['один', 'единица', '1'],
  [Value.Two]: ['два', '2'],
  [Value.Three]: ['три', '3'],
  [Value.Four]: ['четыре', '4'],
  [Value.Five]: ['пять', '5'],
  [Value.Six]: ['шесть', '6'],
  [Value.Seven]: ['семь', '7'],
  [Value.Eight]: ['восемь', '8'],
  [Value.Nine]: ['девять', '9'],
  [Value.Ten]: ['десять', '10'],
  [Value.Page]: ['принцесса', 'валет', 'вестник'],
  [Value.Knight]: ['принц', 'кавалер', 'всадник'],
  [Value.Queen]: ['дама', 'сивилла'],
  [Value.King]: ['фараон', 'рыцарь'],
};

export const synonymsByCategory = {
  [Category.Sticks]: [
    'жезлов',
    'клюшек',
    'треф',
    'крестов',
    'палок',
    'скипетров',
    'булав',
    'посохов',
  ],
  [Category.Cups]: ['чаш', 'червей', 'кубков'],
  [Category.Swords]: ['сабель', 'пик', 'копьев', 'мечей'],
  [Category.Pentacles]: ['монет', 'дисков', 'динариев', 'бубен', 'пентаклей'],
};

export const urlsByCategory = {
  [Category.Sticks]: '/taro/znachenie-kart/posokhi/',
  [Category.Cups]: '/taro/znachenie-kart/kubki/',
  [Category.Swords]: '/taro/znachenie-kart/mechi/',
  [Category.Pentacles]: '/taro/znachenie-kart/pentakli/',
};

const generateSynonyms = (category: Category, value: Value) => {
  const synonyms = synonymsByCategory[category]?.reduce(
    (acc, categorySynonym) => {
      synonymsByValue[value]?.forEach((valueSynonym) =>
        acc.push(`${valueSynonym} ${categorySynonym}`),
      );

      return acc;
    },
    [] as string[],
  );

  return synonyms;
};

export const tarotList = [
  {
    title: 'Старшие Арканы',
    short: 'Старшие Арканы',
    slug: 'starshie-arkany',
    description: (
      <>
        <Link to="/taro/znachenie-kart/starshie-arkany/">Старшие арканы</Link> в
        Таро состоят из 22 карт, каждая из которых несет глубокий символический
        смысл. Старшие арканы начинаются с карты “Шут” и заканчивается картой
        “Мир”, таким образом представляя архетипические аспекты человеческой
        жизни. Они служат проводниками на духовном пути, помогая понять, в чем
        заключается настоящий вызовна поворотных точках жизни гадающего. Старшие
        арканы - не только средство предсказания, но и инструмент самопознания,
        помогающий понять свое место в мире. Изучение этих карт обогащает
        интуицию и открывает новые пути в личном развитии.
      </>
    ),
    cards: [
      {
        id: '22',
        name: 'Шут',
        slug: 'shut',
        code: 'fool',
        straight:
          'индивидуальность, новое начало, духовное развитие, неискушенность, креативность.',
        inverted:
          'детская легкомысленность, безрассудность, беспечность, наивность, ведомость.',
        synonyms: ['Дурак'],
      },
      {
        id: '1',
        name: 'Маг',
        slug: 'mag',
        code: 'mag',
        straight: 'талант, сильная воля, трансформация, умение убеждать.',
        inverted:
          'манипуляция, мошенничество, безответственность, бесчестие, беспокойство.',
      },
      {
        id: '2',
        name: 'Верховная Жрица',
        slug: 'verkhovnaya-zhritsa',
        code: 'high_priestess',
        straight:
          'мудрость, чуткость, интуиция, древние тайны, духовная любовь, архетип взрослой женщины.',
        inverted:
          'поверхностность, тщеславие, чрезмерная страсть, подавленное внутреннее чутье, скрытые мотивы.',
      },
      {
        id: '3',
        name: 'Императрица',
        slug: 'imperatritsa',
        code: 'empress',
        straight:
          'развитие, поддержка, забота, плодородие, изобилие, отзывчивость. Архетип матери.',
        inverted:
          'неуверенность, ссоры в семье, неверность, незащищенность, потеря привлекательности.',
      },
      {
        id: '4',
        name: 'Император',
        slug: 'imperator',
        code: 'emperor',
        straight:
          'властный, сильный человек. Архетип отца. Рациональность, четкая структура, стабильность.',
        inverted:
          'незрелость, препятствия, доброжелательность, сострадание. Также: жестокость, упрямство.',
      },
      {
        id: '5',
        name: 'Верховный Жрец',
        slug: 'verkhovnyy-zhrets',
        code: 'hierophant',
        straight:
          'догмы, социальные договоренности, традиции, семейные ценности, предвидение, интеллект.',
        inverted:
          'социализация, слабость, доброта, гармония. Также: разочарование, мстительность, алчность.',
        synonyms: ['Иерофант'],
      },
      {
        id: '6',
        name: 'Влюбленные',
        slug: 'vlyublennye',
        code: 'lovers',
        straight:
          'нравственный выбор, родственные души, единение, возвышенная или приземленная любовь.',
        inverted:
          'неудачи, глупые желания, лживость, отсутствие взаимности, неспособность принять решения.',
      },
      {
        id: '7',
        name: 'Колесница',
        slug: 'kolesnitsa',
        code: 'chariot',
        straight:
          'победа над трудностями, самоопределение, возможные пути, амбициозность, развитие.',
        inverted:
          'беспорядок, стычки, судебные разбирательства, неудачи, бессилие, крушение.',
      },
      {
        id: '8',
        name: 'Сила',
        slug: 'sila',
        code: 'strength',
        straight:
          'внутренняя добродетель, власть, сила, способность действовать, самоконтроль, мужество.',
        inverted:
          'тирания, превышение полномочий, неуверенность в себе, слабость, вражда, конфликты.',
      },
      {
        id: '9',
        name: 'Отшельник',
        slug: 'otshelnik',
        code: 'hermit',
        straight:
          'мудрость, одиночество, духовный наставник или ментор, погружение во внутренний мир.',
        inverted: 'страх, нерешительность, лицемерие, интриги, изоляция.',
      },
      {
        id: '10',
        name: 'Колесо Фортуны',
        slug: 'koleso-fortuny',
        code: 'wheel_of_fortune',
        straight:
          'смена статуса, удача, резкие перемены, жизненные циклы, периодические изменения.',
        inverted: 'экспансия, избыток, достаток, изобилие.',
      },
      {
        id: '11',
        name: 'Справедливость',
        slug: 'spravedlivost',
        code: 'justice',
        straight:
          'карма, ответственность в юридических вопросах, честь, объективность, уважение к иерархии.',
        inverted:
          'непреклонность, жесткость, излишняя строгость, соблюдение закона или порядка во всем, без исключений.',
      },
      {
        id: '12',
        name: 'Повешенный',
        slug: 'poveshennyy',
        code: 'hanged_man',
        straight:
          'другая точка зрения, широта взглядов, жертва, идеализм, отказ от материального, трансформация.',
        inverted: 'эгоцентризм, отсутствие интереса, стагнация, промедление.',
      },
      {
        id: '13',
        name: 'Смерть',
        slug: 'smert',
        code: 'death',
        straight:
          'изменение или трансформация текущего состояние, переход к чему-то иному, внезапные перемены.',
        inverted:
          'пассивность, апатия, вялость, нежелание или страх что-то менять, упадок.',
      },
      {
        id: '14',
        name: 'Умеренность',
        slug: 'umerennost',
        code: 'temperance',
        straight:
          'баланс, удовлетворение тем, что есть, соединение, выздоровление, обретение сил.',
        inverted:
          'конфликт интересов, чрезмерная религиозность, сектантство, неудачные решения, раскол, церковные дела.',
      },
      {
        id: '15',
        name: 'Дьявол',
        slug: 'dyavol',
        code: 'devil',
        straight:
          'соблазн, сексуальная притягательность, эгоистичность, жажда чувственных удовольствий.',
        inverted:
          'недовольство, слабость, опрометчивость, автономия, избавление, откровение.',
      },
      {
        id: '16',
        name: 'Башня',
        slug: 'bashnya',
        code: 'tower',
        straight:
          'резкое изменение, разрушение устоявшегося порядка, катастрофа, справедливое возмездие.',
        inverted:
          'избегание перемен. Также может означать, что основное значение этой карты будет выражено в меньшей степени.',
      },
      {
        id: '17',
        name: 'Звезда',
        slug: 'zvezda',
        code: 'star',
        straight:
          'хороший знак, отличные условия, вдохновение, обретение надежды.',
        inverted:
          'самоуверенность, напыщенность, презрительность. Также: отсутствие надежды и перспектив.',
      },
      {
        id: '18',
        name: 'Луна',
        slug: 'luna',
        code: 'moon',
        straight:
          'обман, странные события, неопределенные или запутанные ситуации, интуиция, таинственность.',
        inverted:
          'изменчивость, неустойчивость, скрытность. Также может означать, что основное значение этой карты будет выражено в меньшей степени.',
      },
      {
        id: '19',
        name: 'Солнце',
        slug: 'solntse',
        code: 'sun',
        straight:
          'жизненная сила, подарок судьбы, ясное видение, искренность, победа, удача.',
        inverted:
          'суета, нервозность, притворство, неясность, запоздалое признание или успех.',
      },
      {
        id: '20',
        name: 'Страшный Суд',
        slug: 'strashnyy-sud',
        code: 'judgement',
        straight:
          'обновление, второе дыхание, возрождение, обретение известности, получение по заслугам.',
        inverted: 'трусость, глупость, промедление с решением, неодобрение.',
      },
      {
        id: '21',
        name: 'Мир',
        slug: 'mir',
        code: 'world',
        straight:
          'удачное завершение, достижение целей, поворотные события, успех выше ожиданий.',
        inverted:
          'спад, упадок, отсутствие развития и движения, апатия, стагнация.',
      },
    ],
  },
  {
    title: 'Масть Посохов',
    short: 'Посохи',
    slug: 'posokhi',
    description: (
      <>
        <Link to="/taro/znachenie-kart/posokhi/">Масть Посохов</Link> относится
        к{' '}
        <Link to="/taro/znachenie-kart/mladshie-arkany/">младшим арканам</Link>{' '}
        и связана со стихией огня, энергией, стихийностью. На картах часто
        изображены горящие посохи, скипетры или дубины с пробивающимися
        листьями, что символизирует зарождение нового. Масть говорит о наличии
        творческой энергии, предприимчивости и инициативы в делах. Если большая
        часть карт в раскладе — посохи, то это означает готовность к действиям.
        В данный период человек может испытывать подъем сил, брать на себя
        непомерную задачу, а также не иметь терпения спокойно обдумать
        происходящее.
      </>
    ),
    cards: [
      {
        id: '78',
        name: 'Туз Посохов',
        slug: 'tuz-posokhov',
        code: 'batons_ace',
        straight: 'начинания, зарождающаяся страсть, креативность.',
        inverted: 'потеря сил, крах, ригидность, кризис, разрушение.',
        synonyms: generateSynonyms(Category.Sticks, Value.One),
      },
      {
        id: '77',
        name: 'Двойка Посохов',
        slug: 'dvoyka-posokhov',
        code: 'batons_two',
        straight:
          'потеря силы воли, принуждение, давление со стороны окружающих, распутье.',
        inverted: 'ошибки, промахи, проблемы, неудачи, невезение.',
        synonyms: generateSynonyms(Category.Sticks, Value.Two),
      },
      {
        id: '76',
        name: 'Тройка Посохов',
        slug: 'troyka-posokhov',
        code: 'batons_three',
        straight:
          'стремления на паузе, переоценка ситуации, перспективные идеи, подготовка и планирование.',
        inverted:
          'промежуточное состояние, ригидность, отсутствие свободы мысли, опасения.',
        synonyms: generateSynonyms(Category.Sticks, Value.Three),
      },
      {
        id: '75',
        name: 'Четверка Посохов',
        slug: 'chetverka-posokhov',
        code: 'batons_four',
        straight:
          'усилие, направленное на завершение, цель в поле зрения, оттачивание мастерства.',
        inverted: 'ложь, мошенничество, лжец в окружении, раздор, споры.',
        synonyms: generateSynonyms(Category.Sticks, Value.Four),
      },
      {
        id: '74',
        name: 'Пятерка Посохов',
        slug: 'pyaterka-posokhov',
        code: 'batons_five',
        straight:
          'безобидный вызов, испытание мужества, изобретательность, планомерные действия, трудолюбие.',
        inverted:
          'ссоры, разногласия, сражения. Изменение мнений, стремительность, внутренний конфликт.',
        synonyms: generateSynonyms(Category.Sticks, Value.Five),
      },
      {
        id: '73',
        name: 'Шестерка Посохов',
        slug: 'shesterka-posokhov',
        code: 'batons_six',
        straight:
          'оправданная гордость, скоротечный успех, признание окружающих.',
        inverted: 'барьеры, преграды, зыбкость, шаткость, неясные перспективы.',
        synonyms: generateSynonyms(Category.Sticks, Value.Six),
      },
      {
        id: '72',
        name: 'Семерка Посохов',
        slug: 'semerka-posokhov',
        code: 'batons_seven',
        straight:
          'расплата, торговля за условия сделки или соглашения, настойчивость, целеустремленность.',
        inverted:
          'уязвимость, вероятность воровства, угона или потери чего-то, необходимость защиты.',
        synonyms: generateSynonyms(Category.Sticks, Value.Seven),
      },
      {
        id: '71',
        name: 'Восьмерка Посохов',
        slug: 'vosmerka-posokhov',
        code: 'batons_eight',
        straight:
          'коммуникация, озарение, быстрая реакция, непродолжительное окно возможностей.',
        inverted: 'поспешность, пустые хлопоты, суета, ненужная деятельность.',
        synonyms: generateSynonyms(Category.Sticks, Value.Eight),
      },
      {
        id: '70',
        name: 'Девятка Посохов',
        slug: 'devyatka-posokhov',
        code: 'batons_nine',
        straight:
          'самодисциплина, накопление ресурсов, ожидание своего часа, уверенность в своей позиции.',
        inverted:
          'нерасторопность, косность, равнодушие, упущенные возможности.',
        synonyms: generateSynonyms(Category.Sticks, Value.Nine),
      },
      {
        id: '69',
        name: 'Десятка Посохов',
        slug: 'desyatka-posokhov',
        code: 'batons_ten',
        straight:
          'трудности, большое количество дел, отсутствие помощи, настойчивость, планомерные действия.',
        inverted:
          'депрессивное состояние, упадок, крах надежд, изнуренное состояние, отсутствие сил.',
        synonyms: generateSynonyms(Category.Sticks, Value.Ten),
      },
      {
        id: '68',
        name: 'Паж Посохов',
        slug: 'pazh-posokhov',
        code: 'batons_page',
        straight:
          'тот, кто учится чему-то. Необходимо тренировать волю, оценивать свои силы и быть точным.',
        inverted:
          'отсутствие опыта, вздорные идеи, горячность, излишняя поспешность.',
        synonyms: generateSynonyms(Category.Sticks, Value.Page),
      },
      {
        id: '67',
        name: 'Рыцарь Посохов',
        slug: 'rytsar-posokhov',
        code: 'batons_knight',
        straight:
          'тот, кто находится в поисках приключений и острых ощущений. Проницательность, целеустремленность.',
        inverted:
          'начальник или лидер без опыта, бахвальство, призрачные цели, подводные камни.',
        synonyms: generateSynonyms(Category.Sticks, Value.Knight),
      },
      {
        id: '66',
        name: 'Королева Посохов',
        slug: 'koroleva-posokhov',
        code: 'batons_queen',
        straight:
          'та, кто привлекает других своей харизмой, красотой и опытом. Свободная любовь, близость, секретность.',
        inverted:
          'капризная или склочная женщина, бесцеремонность, зависть, инстинкты, роковая или запретная страсть.',
        synonyms: generateSynonyms(Category.Sticks, Value.Queen),
      },
      {
        id: '65',
        name: 'Король Посохов',
        slug: 'korol-posokhov',
        code: 'batons_king',
        straight:
          'тот, кто управляет ситуацией благодаря силе своей личности. Трудолюбие, терпение. Отец.',
        inverted:
          'тот, кто выше по иерархии, дает указание и совет, которому нужно следовать. Руководство и распоряжение.',
        synonyms: generateSynonyms(Category.Sticks, Value.King),
      },
    ],
  },
  {
    title: 'Масть кубков',
    short: 'Кубки',
    slug: 'kubki',
    description: (
      <>
        <Link to="/taro/znachenie-kart/kubki/">Масть Кубков</Link> принадлежит к
        водной стихии. Символизирует двойственную натуру: с одной стороны мир,
        спокойствие, а с другой стороны — глубина чувств, мягкость. Кубки
        знаменуют важность внутренних чувств и переживаний. В раскладах карты
        трактуют, как романтичность, влюбленность или творчество. А еще масть
        символизирует непостоянство, так как стихия водная очень переменчива.
        Чувства, эмоции и переживания быстротечны.
      </>
    ),
    cards: [
      {
        id: '36',
        name: 'Туз Кубков',
        slug: 'tuz-kubkov',
        code: 'cups_ace',
        straight:
          'сопереживание, подарок, любовь в сердце, изобилие, празднество.',
        inverted:
          'трудности, отстуствие результатов, стагнация, регресс, деградация.',
        synonyms: generateSynonyms(Category.Cups, Value.One),
      },
      {
        id: '35',
        name: 'Двойка Кубков',
        slug: 'dvoyka-kubkov',
        code: 'cups_two',
        straight:
          'партнерство, новые связи, вероятность романа, установление дружеских отношений.',
        inverted:
          'невзаимные чувства, пропасть между тем чего хочется и тем что есть.',
        synonyms: generateSynonyms(Category.Cups, Value.Two),
      },
      {
        id: '34',
        name: 'Тройка Кубков',
        slug: 'troyka-kubkov',
        code: 'cups_three',
        straight:
          'дружба, игривость совместная деятельность, помощь, праздник.',
        inverted:
          'маниакальное поведение, регулярный ущерб, бездумные траты, трата энергии на пустяки.',
        synonyms: generateSynonyms(Category.Cups, Value.Three),
      },
      {
        id: '33',
        name: 'Четверка Кубков',
        slug: 'chetverka-kubkov',
        code: 'cups_four',
        straight:
          'разобщение, изоляция, скука и тоска, мелочные желания, неопределенность в отношениях.',
        inverted:
          'тоска, нестабильность чувств в романтических отношениях, усталость.',
        synonyms: generateSynonyms(Category.Cups, Value.Four),
      },
      {
        id: '32',
        name: 'Пятерка Кубков',
        slug: 'pyaterka-kubkov',
        code: 'cups_five',
        straight:
          'фрустрация, разочарование, разбитое сердце, потери, чувство страха.',
        inverted:
          'маниакальное состояние, безнадежность, гнетущие предчувствие.',
        synonyms: generateSynonyms(Category.Cups, Value.Five),
      },
      {
        id: '31',
        name: 'Шестерка Кубков',
        slug: 'shesterka-kubkov',
        code: 'cups_six',
        straight:
          'возвращение к прошлому, детские воспоминания, прощение, выздоровление.',
        inverted: 'конфликтные вопросы, недостаток эмоций, размолвки в семье.',
        synonyms: generateSynonyms(Category.Cups, Value.Six),
      },
      {
        id: '30',
        name: 'Семерка Кубков',
        slug: 'semerka-kubkov',
        code: 'cups_seven',
        straight:
          'склонность к иллюзиям и фантазиям, самовлюбленность, конфликтующие желания, сюрпризы.',
        inverted:
          'розовые мечты, несбыточные фантазии, неразумные желания, неадекватные притязания.',
        synonyms: generateSynonyms(Category.Cups, Value.Seven),
      },
      {
        id: '29',
        name: 'Восьмерка Кубков',
        slug: 'vosmerka-kubkov',
        code: 'cups_eight',
        straight:
          'завершение, объективные оценки, скромность, честность, легкие развлечения и отношения.',
        inverted: 'смятение, лень, вялость, апатия, гедонизм, трата времени.',
        synonyms: generateSynonyms(Category.Cups, Value.Eight),
      },
      {
        id: '28',
        name: 'Девятка Кубков',
        slug: 'devyatka-kubkov',
        code: 'cups_nine',
        straight:
          'хорошее здоровье, достаток, удовлетворение тем что есть, творческое пробуждение.',
        inverted: 'ребячество, легкомыслие, странные сны, бредовые идеи.',
        synonyms: generateSynonyms(Category.Cups, Value.Nine),
      },
      {
        id: '27',
        name: 'Десятка Кубков',
        slug: 'desyatka-kubkov',
        code: 'cups_ten',
        straight:
          'благополучие в семье, счастье и радость, хорошее состояние дел, прекрасные результаты.',
        inverted: 'споры, конфликты, недопонимание, упадок, изоляция.',
        synonyms: generateSynonyms(Category.Cups, Value.Ten),
      },
      {
        id: '26',
        name: 'Паж Кубков',
        slug: 'pazh-kubkov',
        code: 'cups_page',
        straight:
          'получение новостей или сообщений, невинный человек, любознательная натура, начинающий.',
        inverted:
          'увлечение, преданность, привязанность, очарование, шарм, уловки, притворство.',
        synonyms: generateSynonyms(Category.Cups, Value.Page),
      },
      {
        id: '25',
        name: 'Рыцарь Кубков',
        slug: 'rytsar-kubkov',
        code: 'cups_knight',
        straight:
          'соблазнение, флирт, выгодные предложение, приятные подарки, одобрительные слова.',
        inverted:
          'ложь, афера, изворотливость, двуличие, лицемерие, фальсификация.',
        synonyms: generateSynonyms(Category.Cups, Value.Knight),
      },
      {
        id: '24',
        name: 'Королева Кубков',
        slug: 'koroleva-kubkov',
        code: 'cups_queen',
        straight:
          'верная подруга или возлюбленная, ум и интуиция, умение видеть суть вещей.',
        inverted:
          'может означать как просто хорошую женщину, так и незаурядную женщину, но с противоречивым характером - упертая, вздорная, ветреная, порочная.',
        synonyms: generateSynonyms(Category.Cups, Value.Queen),
      },
      {
        id: '23',
        name: 'Король Кубков',
        slug: 'korol-kubkov',
        code: 'cups_king',
        straight:
          'изобретатель, творческий и оригинальный человек, покровитель и наставник.',
        inverted:
          'лживый, двуличный человек; обман, шантаж, несправедливость, распущенность, клевета.',
        synonyms: generateSynonyms(Category.Cups, Value.King),
      },
    ],
  },
  {
    title: 'Масть Мечей',
    short: 'Мечи',
    slug: 'mechi',
    description: (
      <>
        <Link to="/taro/znachenie-kart/mechi/">Масть Мечей</Link> выделяет
        изображенный на картах меч, несущий двойной смысл, который можно
        раскрыть, изучив туз этой масти. В основном Мечи связаны с воздушной
        стихией. Поэтому масть ассоциируется с чем-то эфемерным, едва уловимым,
        как призрак или идея. Мечи символизируют победу разума над телом.
        Примечательно и то, что на карте показаны природные пейзажи, притом
        довольно суровые — с валунами и утесами Важным моментом является также
        вид небесного свода. Он может быть безоблачным или же затянутым темными
        тучами.
      </>
    ),
    cards: [
      {
        id: '64',
        name: 'Туз Мечей',
        slug: 'tuz-mechey',
        code: 'swords_ace',
        straight:
          'сила, триумф, победа благодаря уму и смекалке, начало нового амбициозного дела.',
        inverted:
          'пиррова победа, тяжелое противостояние, жесткий конфликт, коварство, уловки.',
        synonyms: generateSynonyms(Category.Swords, Value.One),
      },
      {
        id: '63',
        name: 'Двойка Мечей',
        slug: 'dvoyka-mechey',
        code: 'swords_two',
        straight:
          'сбалансированный подход, осторожный прогресс, бдительность, непримиримое соперничество.',
        inverted:
          'судебные тяжбы, расторжение брака или договора, дележ, необдуманность.',
        synonyms: generateSynonyms(Category.Swords, Value.Two),
      },
      {
        id: '62',
        name: 'Тройка Мечей',
        slug: 'troyka-mechey',
        code: 'swords_three',
        straight:
          'сожаление, эмоциональная драма, непонимание, влечение, триумф.',
        inverted:
          'истощение, измождение, упадок сил, хронические боли и страдания.',
        synonyms: generateSynonyms(Category.Swords, Value.Three),
      },
      {
        id: '61',
        name: 'Четверка Мечей',
        slug: 'chetverka-mechey',
        code: 'swords_four',
        straight:
          'восстановление, медитация, исцеляющая передышка от проблем, аскеза.',
        inverted:
          'задержка с решением, низкая самооценка, личностный кризис, сожаление, пессимизм.',
        synonyms: generateSynonyms(Category.Swords, Value.Four),
      },
      {
        id: '60',
        name: 'Пятерка Мечей',
        slug: 'pyaterka-mechey',
        code: 'swords_five',
        straight:
          'поражение, несправедливость, злой умысел, обретение бессмысленной свободы.',
        inverted:
          'иррациональность, непоследовательность, уловки, угнетение, изоляция.',
        synonyms: generateSynonyms(Category.Swords, Value.Five),
      },
      {
        id: '59',
        name: 'Шестерка Мечей',
        slug: 'shesterka-mechey',
        code: 'swords_six',
        straight:
          'успокоение после тревог, сочувствие людям, интригующее предложение, тайное становится явным.',
        inverted:
          'дурные знамения, резонные подозрения, опасения, беспокойство, подозрения.',
        synonyms: generateSynonyms(Category.Swords, Value.Six),
      },
      {
        id: '58',
        name: 'Семерка Мечей',
        slug: 'semerka-mechey',
        code: 'swords_seven',
        straight:
          'нестабильная ситуация, возможное предательство, воровство, духовное развитие.',
        inverted:
          'измена, предательство, покидать, сбегать, знаменательное путешествие с непредвиденными событиями.',
        synonyms: generateSynonyms(Category.Swords, Value.Seven),
      },
      {
        id: '57',
        name: 'Восьмерка Мечей',
        slug: 'vosmerka-mechey',
        code: 'swords_eight',
        straight:
          'ограничение, паранойя, разбазаривание своей силы, превышение полномочий, нарушение границ.',
        inverted:
          'вероломство, развязность, раскрытие секретов и тайн, разговорчивость.',
        synonyms: generateSynonyms(Category.Swords, Value.Eight),
      },
      {
        id: '56',
        name: 'Девятка Мечей',
        slug: 'devyatka-mechey',
        code: 'swords_nine',
        straight:
          'парализующий страх, паника, примитивные инстинкты, досада, чувство вины.',
        inverted: 'предательство, низость, мщение, кара, злоба, кровожадность.',
        synonyms: generateSynonyms(Category.Swords, Value.Nine),
      },
      {
        id: '55',
        name: 'Десятка Мечей',
        slug: 'desyatka-mechey',
        code: 'swords_ten',
        straight:
          'окончание черной полосы и неудач, движение вперед, гонение, рефлексия.',
        inverted:
          'неблагоприятные обстоятельства, ложь, предательство, барьеры, препятствия, поражение, подчинение.',
        synonyms: generateSynonyms(Category.Swords, Value.Ten),
      },
      {
        id: '54',
        name: 'Паж Мечей',
        slug: 'pazh-mechey',
        code: 'swords_page',
        straight:
          'тот, кто находится постоянно в поисках знаний, обладает пытливым и любопытным умом.',
        inverted:
          'неискушенный молодой человек или девушка, без опыта. Лицемерие, фальшь, ложь, информатор.',
        synonyms: generateSynonyms(Category.Swords, Value.Page),
      },
      {
        id: '53',
        name: 'Рыцарь Мечей',
        slug: 'rytsar-mechey',
        code: 'swords_knight',
        straight:
          'тот, кто мчится, без плана. Может нанести урон другим из-за невнимательности или безрассудства.',
        inverted:
          'неуравновешенный и неблагоразумный человек. Бестактность, безмозглость, глупость.',
        synonyms: generateSynonyms(Category.Swords, Value.Knight),
      },
      {
        id: '52',
        name: 'Королева Мечей',
        slug: 'koroleva-mechey',
        code: 'swords_queen',
        straight:
          'та, кто проявляет силу действуя за сценой. Может быть более опасной, чем кажется.',
        inverted:
          'двуличная, опасная женщина, с недобрыми намерениями. Несдержанность, озлобленность.',
        synonyms: generateSynonyms(Category.Swords, Value.Queen),
      },
      {
        id: '51',
        name: 'Король Мечей',
        slug: 'korol-mechey',
        code: 'swords_king',
        straight:
          'тот, кто планирует и прорабатывает стратегию, прежде чем действовать. Находит блестящие идеи, для выхода из любой ситуации.',
        inverted:
          'опытный соперник, искусный противник. Конфликт, соперничество, борьба.',
        synonyms: generateSynonyms(Category.Swords, Value.King),
      },
    ],
  },
  {
    title: 'Масть Пентаклей',
    short: 'Пентакли',
    slug: 'pentakli',
    description: (
      <>
        Отличительной особенностью{' '}
        <Link to="/taro/znachenie-kart/pentakli/">масти пентаклей</Link>{' '}
        выступает изображение пятиконечной звезды на монете. Значение масти
        связывается со стихией земли, поэтому в раскладе она символизирует
        устойчивость, незыблемость, материальность. Она указывает на необходимые
        условия при формировании личности, наличие талантов, способностей,
        предпочтений. Если в вашем раскладе карты Жезлов сопровождаются картой
        Пентаклей, тогда идеи получают материальное воплощение.
      </>
    ),
    cards: [
      {
        id: '50',
        name: 'Туз Пентаклей',
        slug: 'tuz-pentakley',
        code: 'coins_ace',
        straight:
          'подарки, возможности, успешное разрешения дел, удачный момент.',
        inverted:
          'неверный выбор, потеря удачного шанса, серый доход, долги и обязательства.',
        synonyms: generateSynonyms(Category.Pentacles, Value.One),
      },
      {
        id: '49',
        name: 'Двойка Пентаклей',
        slug: 'dvoyka-pentakley',
        code: 'coins_two',
        straight:
          'баланс, многозадачность, самонадеянность, выгодное финансовое сотрудничество.',
        inverted: 'нехватка денег, поиск кредита, промах, расчет по долгам.',
        synonyms: generateSynonyms(Category.Pentacles, Value.Two),
      },
      {
        id: '48',
        name: 'Тройка Пентаклей',
        slug: 'troyka-pentakley',
        code: 'coins_three',
        straight:
          'работа над общей целью, партнерство, коллаборация, кооперация.',
        inverted: 'ущерб, потеря денег, крах, разорение.',
        synonyms: generateSynonyms(Category.Pentacles, Value.Three),
      },
      {
        id: '47',
        name: 'Четверка Пентаклей',
        slug: 'chetverka-pentakley',
        code: 'coins_four',
        straight:
          'экономия, предусмотрительность, страх потерять стабильность, инвестиции, вклады.',
        inverted: 'алчность, стагнация, корысть.',
        synonyms: generateSynonyms(Category.Pentacles, Value.Four),
      },
      {
        id: '46',
        name: 'Пятерка Пентаклей',
        slug: 'pyaterka-pentakley',
        code: 'coins_five',
        straight:
          'отрыв от реальности и мира, грусть, эмоциональная опустошенность, нищета, непритязательность.',
        inverted:
          'проблемы с деньгами, займы, поиск средств или кредитов, выспрашивание денег.',
        synonyms: generateSynonyms(Category.Pentacles, Value.Five),
      },
      {
        id: '45',
        name: 'Шестерка Пентаклей',
        slug: 'shesterka-pentakley',
        code: 'coins_six',
        straight:
          'доброта, щедрость, благотворительность без чувства вины и сожалений.',
        inverted:
          'транжирство, разбазаривание ресурсов, поддержка дармоедов и паразитов.',
        synonyms: generateSynonyms(Category.Pentacles, Value.Six),
      },
      {
        id: '44',
        name: 'Семерка Пентаклей',
        slug: 'semerka-pentakley',
        code: 'coins_seven',
        straight:
          'предусмотрительность, внимание к деталям, планирование наперед, целеустремленность.',
        inverted: 'потеря иллюзий, недоверие, ложное впечатление.',
        synonyms: generateSynonyms(Category.Pentacles, Value.Seven),
      },
      {
        id: '43',
        name: 'Восьмерка Пентаклей',
        slug: 'vosmerka-pentakley',
        code: 'coins_eight',
        straight:
          'обучение, тренировки, расширение своих профессиональных навыков, повышение квалификации.',
        inverted: 'невежество, грязные методы, заурядность.',
        synonyms: generateSynonyms(Category.Pentacles, Value.Eight),
      },
      {
        id: '42',
        name: 'Девятка Пентаклей',
        slug: 'devyatka-pentakley',
        code: 'coins_nine',
        straight:
          'устойчивость, надежность, получение доходов от инвестиций, единение с собой.',
        inverted: 'неустойчивость, незапланированные траты, напрасные расходы.',
        synonyms: generateSynonyms(Category.Pentacles, Value.Nine),
      },
      {
        id: '41',
        name: 'Десятка Пентаклей',
        slug: 'desyatka-pentakley',
        code: 'coins_ten',
        straight:
          'победа, преодоление препятствий, финансовый рост, улучшение материального состояния.',
        inverted:
          'жульничество, раздоры вокруг наследства, вымогательство, взяточничество.',
        synonyms: generateSynonyms(Category.Pentacles, Value.Ten),
      },
      {
        id: '40',
        name: 'Паж Пентаклей',
        slug: 'pazh-pentakley',
        code: 'coins_page',
        straight:
          'тот, кто изучил истинный порядок вещей и понимает как устроен мир, молодая энергия, новые идеи.',
        inverted:
          'невнимательность, поверхностность, самоуверенность, безрассудность.',
        synonyms: generateSynonyms(Category.Pentacles, Value.Page),
      },
      {
        id: '39',
        name: 'Рыцарь Пентаклей',
        slug: 'rytsar-pentakley',
        code: 'coins_knight',
        straight:
          'тот, кто борется с несправедливостью и защищает угнетенных. Выступает на стороне слабых и обделенных.',
        inverted: 'ложь, морок, обман, мошенничество, подтасовка.',
        synonyms: generateSynonyms(Category.Pentacles, Value.Knight),
      },
      {
        id: '38',
        name: 'Королева Пентаклей',
        slug: 'koroleva-pentakley',
        code: 'coins_queen',
        straight:
          'та, кто щедро одаривает жизненными благами. Великодушная, заботливая. Ресурс благосостояния.',
        inverted: 'некомпетентная, неблагоразумная и примитивная женщина.',
        synonyms: generateSynonyms(Category.Pentacles, Value.Queen),
      },
      {
        id: '37',
        name: 'Король Пентаклей',
        slug: 'korol-pentakley',
        code: 'coins_king',
        straight:
          'тот, кто подходит к решению вопросов с прагматической стороны, приходит к успеху, преодолевая барьеры.',
        inverted:
          'тайный неприятель, тот, кто использует грязные методы. Честолюбие, тщеславие.',
        synonyms: generateSynonyms(Category.Pentacles, Value.King),
      },
    ],
  },
];

const minorArcanes = {
  title: 'Младшие Арканы',
  short: 'Младшие Арканы',
  slug: 'mladshie-arkany',
};

export const tarotCategories = [
  ...tarotList.map(({ slug }) => slug),
  minorArcanes.slug,
].join('|');

export const tarotCardSlugs = tarotList
  .reduce((acc, category) => {
    const slugs = category.cards.map(({ slug }) => slug);

    acc.push(...slugs);

    return acc;
  }, [] as string[])
  .join('|');

export const CODES_BY_CATEGORY = {
  'starshie-arkany': 'major',
  'mladshie-arkany': 'minor',
  posokhi: 'batons',
  kubki: 'cups',
  pentakli: 'coins',
  mechi: 'swords',
};

export const getCurrentTarot = (
  key: keyof (typeof tarotList)[0]['cards'][0],
  value: string | number,
) =>
  tarotList.reduce(
    (acc, category) => {
      const card = category.cards.find((item) => item[key] === `${value}`);

      return card || acc;
    },
    {} as (typeof tarotList)[0]['cards'][0],
  );

const TAROT_SECTIONS = [
  'tarot',
  'taromain',
  'tarocards',
  'tarocategory',
  'taroonecard',
  'taroonline',
  'tarofuture',
  'tarolove',
  'taroshort',
  'tarobirthdate',
  'taroguide',
  'taroforecast',
  'taroforecastbysign',
];

export const selectIsTarotPage = (state: IAppState) =>
  state.runtime.currentPage === 'fortune' &&
  TAROT_SECTIONS.includes(state.runtime.currentSection);
