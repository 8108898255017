export interface ProjectLinkType {
  title: string;
  active: boolean;
  href: string;
  target?: string;
  icon?: string;
  code?: string | RegExp;
  onClick?: (event: React.MouseEvent) => void;
  children?: ProjectLinkType[];
  isExternal?: boolean;
}

export const projectLinks = (): ProjectLinkType[] => [
  {
    href: '/',
    title: 'Гороскопы',
    active: false,
    code: 'horoscope-main',
    icon: 'https://static.rambler.ru/icons/horoscope/topline/regular/horoscopes.svg',
    children: [
      {
        href: '/',
        title: 'Общий гороскоп',
        code: /horoscope-main|horoscope-general-.+\/.+/g,
        active: false,
      },
      {
        href: '/erotic/',
        title: 'Любовный',
        code: /horoscope-love-main|horoscope-love-.+\/.+/g,
        active: false,
      },
      {
        href: '/career/',
        title: 'Финансовый',
        code: /horoscope-finance-main|horoscope-finance-.+\/.+/g,
        active: false,
      },
      {
        href: '/sex-horoscope/today/',
        title: 'Сексуальный',
        code: /horoscope-sex-all\/today|horoscope-sex-.+\/.+/g,
        active: false,
      },
      {
        href: '/woman/today/',
        title: 'Женский',
        code: /horoscope-woman-main|horoscope-woman-.+\/.+/g,
        active: false,
      },
      {
        href: '/man/',
        title: 'Мужской',
        code: /horoscope-man-main|horoscope-man-.+\/.+/g,
        active: false,
      },
      {
        href: '/znaki-zodiaka/',
        title: 'Знаки зодиака',
        active: false,
      },
    ],
  },
  {
    href: '/znaki-zodiaka/',
    title: 'Знаки зодиака',
    code: 'horoscope-description-main',
    icon: 'https://static.rambler.ru/icons/horoscope/topline/regular/zodiac.svg',
    active: false,
    children: [
      {
        href: '/znaki-zodiaka/',
        title: 'Найти знак',
        code: 'horoscope-description-main',
        active: false,
      },
      {
        href: '/znaki-zodiaka/kresty/',
        title: 'Кресты знаков',
        code: 'horoscope-description-kresty',
        active: false,
      },
      {
        href: '/znaki-zodiaka/in-i-yan/',
        title: 'Инь и ян знаков',
        code: 'horoscope-description-in-i-yan',
        active: false,
      },
      {
        href: '/znaki-zodiaka/stihii/',
        title: 'Стихии знаков',
        code: 'horoscope-description-stihii',
        active: false,
      },
      {
        href: '/znaki-zodiaka/woman/',
        title: 'Женщины по знакам',
        code: 'horoscope-description-woman',
        active: false,
      },
      {
        href: '/znaki-zodiaka/man/',
        title: 'Мужчины по знакам',
        code: 'horoscope-description-man',
        active: false,
      },
      {
        href: '/znaki-zodiaka/birthstone/',
        title: 'Камни по знакам',
        code: 'horoscope-birthstone-main',
        active: false,
      },
    ],
  },
  {
    title: 'Таро',
    href: '/taro/',
    code: 'fortune-taromain',
    icon: 'https://static.rambler.ru/icons/horoscope/topline/regular/tarot.svg',
    active: false,
    children: [
      {
        title: 'Главная',
        href: '/taro/',
        code: 'fortune-taromain',
        active: false,
      },
      {
        title: 'Таро онлайн',
        href: '/taro/online/',
        // taro а не tarot для правильной подсветки меню, см src/common/utils/navigation.ts:6
        code: /fortune-taro(t|online|future|love|short)/g,
        active: false,
      },
      {
        title: 'Гадание по дате рождения',
        href: '/taro/data-rozhdeniya/',
        code: 'fortune-tarobirthdate',
        active: false,
      },
      {
        title: 'Гадания на Таро самостоятельно',
        href: '/taro/samostoyatelno/',
        code: 'fortune-taroguide',
        active: false,
      },
      {
        title: 'Значение карт Таро',
        href: '/taro/znachenie-kart/',
        code: /fortune-taro(cards|onecard|category)/g,
        active: false,
      },
      {
        title: 'Таро прогноз 2025',
        href: '/taro/goroskop/',
        code: /fortune-taro(forecast|forecastaries)/g,
        active: false,
      },
    ],
  },
  {
    href: '/sovmestimost-znakov-zodiaka/',
    title: 'Совместимость',
    code: /horoscope-compatibility-all|horoscope-compatibility-.+|horoscope-compatibility-woman-.+\/man-.+/g,
    icon: 'https://static.rambler.ru/icons/horoscope/topline/regular/compatibility.svg',
    active: false,
  },
  {
    title: 'Сонник',
    href: '/dreams/',
    code: /dreams-main|dreams-word-.+/g,
    icon: 'https://static.rambler.ru/icons/horoscope/topline/regular/dreams.svg',
    active: false,
    children: [
      {
        title: 'Найти сон',
        href: '/dreams/',
        code: 'dreams-main',
        active: false,
      },
      {
        href: '/dreams/sonnik-po-dnyam/',
        title: 'Сны по дням недели',
        code: /dreams-periods-sonnik-po-dnyam|dreams-periods-.+/g,
        active: false,
      },
      {
        href: '/dreams/letter/a/',
        title: 'Сонник по алфавиту',
        code: /dreams-letters|dreams-letters-.+/g,
        active: false,
      },
    ],
  },
  {
    title: 'Гадания',
    href: '/gadanie-online/',
    code: 'fortune-main',
    icon: 'https://static.rambler.ru/icons/horoscope/topline/regular/divination.svg',
    active: false,
    children: [
      {
        title: 'Все гадания',
        href: '/gadanie-online/',
        code: 'fortune-main',
        active: false,
      },
      {
        href: '/gadanie-online/taro-keltskiy-krest/',
        title: 'Кельтский крест',
        active: false,
      },
      {
        href: '/gadanie-online/kofeynaya-gushcha/',
        title: 'Кофейная гуща',
        code: 'fortune-coffee',
        active: false,
      },
      {
        href: '/gadanie-online/gadanie-na-runah/',
        title: 'Гадание на рунах',
        code: 'fortune-runes',
        active: false,
      },
      {
        href: '/taro/lyubov/',
        title: 'Гадание на любовь',
        active: false,
      },
      {
        href: '/taro/da-net/',
        title: 'Гадание да/нет',
        active: false,
      },
      {
        href: '/taro/budushchee/',
        title: 'Гадание на будущее',
        active: false,
      },
      {
        href: '/taro/data-rozhdeniya/',
        title: 'Гадание на дату рождения',
        active: false,
      },
    ],
  },
  {
    title: 'Календари',
    href: '/moon/calendar/',
    code: 'horo-calendars',
    icon: 'https://static.rambler.ru/icons/horoscope/topline/regular/calendar.svg',
    active: false,
    children: [
      {
        title: 'Лунный',
        href: '/moon/calendar/',
        code: /moon-calendar-main|moon-calendar-.+/g,
        active: false,
      },
      {
        title: 'Стрижки',
        href: '/hair/',
        code: /hair-main|hair-period-.+|hair-date-.+/g,
        active: false,
      },
      {
        title: 'Для садовода',
        href: '/sadovod/',
        code: /garden-main|garden-period-.+/g,
        active: false,
      },
      {
        title: 'Солнечный',
        href: '/sun/calendar/',
        code: /sun-calendar-today|sun-calendar-.+/g,
        active: false,
      },
      {
        title: 'Именины',
        href: '/names/calendar/',
        active: false,
      },
      {
        title: 'Приметы',
        href: '/primety/calendar/',
        active: false,
      },
    ],
  },
  {
    title: 'Дизайн человека',
    href: '/dizain-cheloveka/',
    code: /humandesign-main|humandesign-result/g,
    icon: 'https://static.rambler.ru/icons/horoscope/topline/regular/humandesign.svg',
    active: false,
    children: [
      {
        title: 'Рассчитать бодиграф',
        href: '/dizain-cheloveka/',
        code: /humandesign-main|humandesign-result/g,
        active: false,
      },
      {
        title: 'Типы',
        href: '/dizain-cheloveka/tipy/',
        code: /humandesign-types|humandesign-type-.+/g,
        active: false,
      },
      {
        title: 'Профили',
        href: '/dizain-cheloveka/profili/',
        code: /humandesign-profiles|humandesign-profile-.+/g,
        active: false,
      },
    ],
  },
  {
    href: '/personal/',
    title: 'Биоритмы',
    code: /dashboard-main|dashboard-main-.+/g,
    icon: 'https://static.rambler.ru/icons/horoscope/topline/regular/biorhythms.svg',
    active: false,
  },
  {
    title: 'Статьи',
    href: '/longread/',
    code: 'articles-list',
    icon: 'https://static.rambler.ru/icons/horoscope/topline/regular/articles.svg',
    active: false,
    children: [
      {
        title: 'Все статьи',
        href: '/longread/',
        code: 'articles-list',
        active: false,
      },
      {
        title: 'Зодиак',
        href: '/zodiac-articles/',
        code: /articles-topic-zodiac-articles|articles-detail-.+\/zodiac-articles/g,
        active: false,
      },
      {
        title: 'Народные приметы',
        href: '/folk-calendar-articles/',
        code: /articles-topic-folk-calendar-articles|articles-detail-.+\/folk-calendar-articles/g,
        active: false,
      },
      {
        title: 'Сонник',
        href: '/dreams-articles/',
        code: /articles-topic-dreams-articles|articles-detail-.+\/dreams-articles/g,
        active: false,
      },
      {
        title: 'Предсказания',
        href: '/predictions-articles/',
        code: /articles-topic-predictions-articles|articles-detail-.+\/predictions-articles/g,
        active: false,
      },
      {
        title: 'Имена',
        href: '/names-articles/',
        code: /articles-topic-names-articles|articles-detail-.+\/names-articles/g,
        active: false,
      },
      {
        title: 'Гадания',
        href: '/fortune-articles/',
        code: /articles-topic-fortune-articles|articles-detail-.+\/fortune-articles/g,
        active: false,
      },
      {
        title: 'Чесалка',
        href: '/chesalka-articles/',
        code: /articles-topic-chesalka-articles|articles-detail-.+\/chesalka-articles/g,
        active: false,
      },
      {
        title: 'Гороскопы',
        href: '/horoscopes-articles/',
        code: /articles-topic-horoscopes-articles|articles-detail-.+\/horoscopes-articles/g,
        active: false,
      },
      {
        title: '2025 год',
        href: '/2025-articles/',
        code: /articles-topic-2025-articles|articles-detail-.+\/2025-articles/g,
        active: false,
      },
      {
        title: 'Значение часов на часах',
        href: '/znachenie-chasov-articles/',
        code: /articles-topic-znachenie-chasov-articles|articles-detail-.+\/znachenie-chasov-articles/g,
        active: false,
      },
      {
        title: 'Нумерология',
        href: '/numerology-articles/',
        code: /articles-topic-numerology-articles|articles-detail-.+\/numerology-articles/g,
        active: false,
      },
      {
        title: 'Чихалка',
        href: '/chihalka-articles/',
        code: /articles-topic-chihalka-articles|articles-detail-.+\/chihalka-articles/g,
        active: false,
      },
      {
        title: 'Таро',
        href: '/taro-articles/',
        code: /articles-topic-taro-articles|articles-detail-.+\/taro-articles/g,
        active: false,
      },
      {
        title: 'Психология',
        href: '/psychology-articles/',
        code: /articles-topic-psychology-articles|articles-detail-.+\/psychology-articles/g,
        active: false,
      },
      {
        title: 'Ванга',
        href: '/vanga-articles/',
        code: /articles-topic-vanga-articles|articles-detail-.+\/vanga-articles/g,
        active: false,
      },
      {
        title: 'Хиромантия',
        href: '/hiromantiya-articles/',
        code: /articles-topic-hiromantiya-articles|articles-detail-.+\/hiromantiya-articles/g,
        active: false,
      },
      {
        title: 'Загробная жизнь',
        href: '/afterlife-articles/',
        code: /articles-topic-afterlife-articles|articles-detail-.+\/afterlife-articles/g,
        active: false,
      },
      {
        title: 'Заговоры и обряды',
        href: '/zagovory-ritualy-articles/',
        code: /articles-topic-zagovory-ritualy-articles|articles-detail-.+\/zagovory-ritualy-articles/g,
        active: false,
      },
      {
        title: 'Восточный гороскоп',
        href: '/eastern-articles/',
        code: /articles-topic-eastern-articles|articles-detail-.+\/eastern-articles/g,
        active: false,
      },
      {
        title: 'Лунный календарь',
        href: '/moon-articles/',
        code: /articles-topic-moon-articles|articles-detail-.+\/moon-articles/g,
        active: false,
      },
      {
        title: 'Ретроградный меркурий',
        href: '/retrogradnyj-merkurij-articles/',
        code: /articles-topic-retrogradnyj-merkurij-articles|articles-detail-.+\/retrogradnyj-merkurij-articles/g,
        active: false,
      },
      {
        title: 'Приметы на новый год',
        href: '/primety-articles/',
        code: /articles-topic-primety-articles|articles-detail-.+\/primety-articles/g,
        active: false,
      },
      {
        title: 'Тесты',
        href: '/tests-articles/',
        code: /articles-topic-tests-articles|articles-detail-.+\/tests-articles/g,
        active: false,
      },
      {
        title: 'Заговоры на новый год',
        href: '/zagovory-articles/',
        code: /articles-topic-zagovory-articles|articles-detail-.+\/zagovory-articles/g,
        active: false,
      },
      {
        title: 'Дизайн человека',
        href: '/dizain-cheloveka-articles/',
        code: /articles-topic-dizain-cheloveka-articles|articles-detail-.+\/dizain-cheloveka-articles/g,
        active: false,
      },
      {
        title: 'Календарь майа',
        href: '/kalendar-majya-articles/',
        code: /articles-topic-kalendar-majya-articles|articles-detail-.+\/kalendar-majya-articles/g,
        active: false,
      },
      {
        title: 'Астрошкола',
        href: '/astro-school-articles/',
        code: /articles-topic-astro-school-articles|articles-detail-.+\/astro-school-articles/g,
        active: false,
      },
      {
        title: 'Йога',
        href: '/yoga-articles/',
        code: /articles-topic-yoga-articles|articles-detail-.+\/yoga-articles/g,
        active: false,
      },
      {
        title: '2024 год',
        href: '/2024-articles/',
        code: /articles-topic-2024-articles|articles-detail-.+\/2024-articles/g,
        active: false,
      },
      {
        title: '2023 год',
        href: '/2023-articles/',
        code: /articles-topic-2023-articles|articles-detail-.+\/2023-articles/g,
        active: false,
      },
      {
        title: '2022 год',
        href: '/2022-articles/',
        code: /articles-topic-2022-articles|articles-detail-.+\/2022-articles/g,
        active: false,
      },
      {
        title: '2021 год',
        href: '/2021-articles/',
        code: /articles-topic-2021-articles|articles-detail-.+\/2021-articles/g,
        active: false,
      },
      {
        title: '2020 год',
        href: '/2020-articles/',
        code: /articles-topic-2020-articles|articles-detail-.+\/2020-articles/g,
        active: false,
      },
      {
        title: 'Новости проекта',
        href: '/projectnews-articles/',
        code: /articles-topic-projectnews-articles|articles-detail-.+\/projectnews-articles/g,
        active: false,
      },
    ],
  },
  {
    title: 'Имена',
    href: '/names/',
    code: /names-main|names-name-.+|names-patronymic-.+/g,
    icon: 'https://static.rambler.ru/icons/horoscope/topline/regular/names.svg',
    active: false,
    children: [
      {
        title: 'Найти имя',
        href: '/names/',
        code: 'names-main',
        active: false,
      },
      {
        title: 'Женские имена',
        href: '/names/female/',
        code: 'names-category-adult/feminine',
        active: false,
      },
      {
        title: 'Мужские имена',
        href: '/names/male/',
        code: 'names-category-adult/masculine',
        active: false,
      },
      {
        title: 'Имена для детей',
        href: '/names/baby/',
        code: /names-category-child$|names-category-child\/(feminine|masculine)/g,
        active: false,
      },
      {
        title: 'Календарь именин',
        href: '/names/calendar/',
        code: /names-calendar|names-month-.+|names-date-.+/g,
        active: false,
      },
      {
        title: 'Алфавит',
        href: '/names/letter/a/',
        code: /names-letters-.+/g,
        active: false,
      },
      {
        title: 'Красивые',
        href: '/names/beautiful/',
        code: /names-category-beautiful|names-category-.+\/beautiful/g,
        active: false,
      },
      {
        title: 'Редкие',
        href: '/names/rare/',
        code: /names-category-rare|names-category-.+\/rare/g,
        active: false,
      },
      {
        title: 'Популярные',
        href: '/names/popular/',
        code: /names-category-popular|names-category-.+\/popular/g,
        active: false,
      },
      {
        title: 'Современные',
        href: '/names/modern/',
        code: /names-category-modern|names-category-.+\/modern/g,
        active: false,
      },
      {
        title: 'Американские',
        href: '/names/american/',
        code: /names-category-american|names-category-.+\/american/g,
        active: false,
      },
      {
        title: 'Английские',
        href: '/names/english/',
        code: /names-category-english|names-category-.+\/english/g,
        active: false,
      },
      {
        title: 'Русские',
        href: '/names/russian/',
        code: /names-category-russian|names-category-.+\/russian/g,
        active: false,
      },
      {
        title: 'Немецкие',
        href: '/names/german/',
        code: /names-category-german|names-category-.+\/german/g,
        active: false,
      },
      {
        title: 'Еврейские',
        href: '/names/jewish/',
        code: /names-category-jewish|names-category-.+\/jewish/g,
        active: false,
      },
      {
        title: 'Славянские',
        href: '/names/slavic/',
        code: /names-category-slavic|names-category-.+\/slavic/g,
        active: false,
      },
      {
        title: 'Французские',
        href: '/names/french/',
        code: /names-category-french|names-category-.+\/french/g,
        active: false,
      },
      {
        title: 'Итальянские',
        href: '/names/italian/',
        code: /names-category-italian|names-category-.+\/italian/g,
        active: false,
      },
      {
        title: 'Татарские',
        href: '/names/tatar/',
        code: /names-category-tatar|names-category-.+\/tatar/g,
        active: false,
      },
      {
        title: 'Армянские',
        href: '/names/armenian/',
        code: /names-category-armenian|names-category-.+\/armenian/g,
        active: false,
      },
      {
        title: 'Мусульманские',
        href: '/names/muslim/',
        code: /names-category-muslim|names-category-.+\/muslim/g,
        active: false,
      },
      {
        title: 'Православные',
        href: '/names/orthodox/',
        code: /names-category-orthodox|names-category-.+\/orthodox/g,
        active: false,
      },
    ],
  },
  {
    title: 'Приметы',
    href: '/primety/',
    code: /omens-main|omens-word-.+/g,
    icon: 'https://static.rambler.ru/icons/horoscope/topline/regular/omens.svg',
    active: false,
    children: [
      {
        title: 'Приметы дня',
        href: '/primety/',
        code: 'omens-main',
        active: false,
      },
      {
        title: 'Календарь примет',
        href: '/primety/calendar/',
        code: /omens-calendar|omens-month-.+|omens-date-.+/g,
        active: false,
      },
      {
        title: 'Алфавит',
        href: '/primety/letter/a/',
        code: /omens-letter-letter\/.+/g,
        active: false,
      },
    ],
  },
  {
    title: 'Китайский гороскоп',
    href: '/chinese/',
    code: /chinese-description|chinese-forecast-.+\/(2021|2022)/g,
    icon: 'https://static.rambler.ru/icons/horoscope/topline/regular/chinese.svg',
    active: false,
    children: [
      {
        title: 'Описание знаков',
        href: '/chinese/',
        code: /chinese-description-.+/g,
        active: false,
      },
      {
        title: 'Прогноз на 2024 год',
        href: '/chinese/forecast/all/2024/',
        code: /chinese-forecast-.+\/2024/g,
        active: false,
      },
      {
        title: 'Прогноз на 2025 год',
        href: '/chinese/forecast/all/2025/',
        code: /chinese-forecast-.+\/2025/g,
        active: false,
      },
      {
        title: 'Cовместимость',
        href: '/chinese/compatibility/all/',
        code: /chinese-compatibility-.+/g,
        active: false,
      },
    ],
  },
  {
    title: 'Ретроградный Меркурий',
    href: '/retrograde-mercury/',
    code: 'retrograde-mercury',
    icon: 'https://static.rambler.ru/icons/horoscope/topline/regular/retrograde.svg',
    active: false,
  },
  {
    title: 'Почтовые рассылки',
    href: '/news-letter/',
    code: 'mailing-main',
    icon: 'https://static.rambler.ru/icons/horoscope/topline/regular/horoscopes.svg',
    active: false,
    children: [],
  },
];
