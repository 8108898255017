export const TNS_IDS = {
  sberSeller: 'rambler_cid1100104-posid2155079',
  rambler: 'rambler_cid1100108-posid2155111',
};

export enum YANDEX_METRICS {
  COMMON = 29581275,
  PORTAL = 26649402,
}

export const RCM_ID = 'RCM-122C';

// один на все версии
export const TOP100_ID = 213403;
// для отслеживания суперпользователей по всему холдингу
export const TOP100_COMMON_ID = 7726560;
// для отслеживания суперюзеров внутри портала Рамблер
export const TOP100_RAMBLER_ID = 7728281;
