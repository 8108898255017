export const namesCategories = {
  beautiful: 'Красивые',
  rare: 'Редкие',
  popular: 'Популярные',
  modern: 'Современные',
  american: 'Американские',
  english: 'Английские',
  russian: 'Русские',
  german: 'Немецкие',
  jewish: 'Еврейские',
  slavic: 'Славянские',
  french: 'Французские',
  italian: 'Итальянские',
  tatar: 'Татарские',
  armenian: 'Армянские',
  muslim: 'Мусульманские',
  orthodox: 'Православные',
};

export const namesTypes = {
  male: 'Мужские',
  female: 'Женские',
  baby: 'Детские',
};

export const namesCategoriesExtended = {
  ...namesCategories,
  girls: 'Для девочек',
  boys: 'Для мальчиков',
};
