export const BUBBLES_GROUPS = ['gender'];

export const BUBBLES_TYPES = {
  gender: ['masculine', 'feminine'],
};

export const BUBBLES_TITLES_BY_TYPE: Record<string, string> = {
  masculine: 'Мужские имена',
  feminine: 'Женские имена',
};
