export const API_ENDPOINT_HUMAN_DESIGN = 'https://api.humandesignx.com';
export const API_ENDPOINT_HUMAN_DESIGN_GEO =
  'https://geoapi.humandesignx.com/api/v1/';
export const HUMAN_DESIGN_PARTNER = 'https://humandesignx.ru/p/rambler';

export const BODYGRAPH_API_URLS = {
  bodygraph: '/api/v1/hd/bodygraph',
};

export const TYPES: Record<string, string> = {
  PROJECTOR: 'Проектор',
  MANIFESTOR: 'Манифестор',
  GENERATOR: 'Генератор',
  MANIFEST_GENERATOR: 'Манифестирующий генератор',
  REFLECTOR: 'Рефлектор',
};

export const PROFILES: Record<
  number,
  {
    title: string;
    rate: string;
    slug: string;
  }
> = {
  13: {
    title: 'Исследователь-мученик',
    rate: '1/3',
    slug: '1-3',
  },
  14: {
    title: 'Исследователь-оппортунист',
    rate: '1/4',
    slug: '1-4',
  },
  24: {
    title: 'Отшельник-оппортунист',
    rate: '2/4',
    slug: '2-4',
  },
  25: {
    title: 'Отшельник-еретик',
    rate: '2/5',
    slug: '2-5',
  },
  35: {
    title: 'Мученик-еретик',
    rate: '3/5',
    slug: '3-5',
  },
  36: {
    title: 'Мученик-ролевая модель',
    rate: '3/6',
    slug: '3-6',
  },
  46: {
    title: 'Оппортунист-ролевая модель',
    rate: '4/6',
    slug: '4-6',
  },
  41: {
    title: 'Оппортунист-исследователь',
    rate: '4/1',
    slug: '4-1',
  },
  51: {
    title: 'Еретик-исследователь',
    rate: '5/1',
    slug: '5-1',
  },
  52: {
    title: 'Еретик-отшельник',
    rate: '5/2',
    slug: '5-2',
  },
  62: {
    title: 'Ролевая модель-отшельник',
    rate: '6/2',
    slug: '6-2',
  },
  63: {
    title: 'Ролевая модель-мученик',
    rate: '6/3',
    slug: '6-3',
  },
};

export const DEFINITIONS: Record<string, string> = {
  NONE: 'Без определения',
  SINGLE: 'Одиночное',
  SPLIT: 'Двойное',
  TRIPLE_SPLIT: 'Тройное',
  QUADRUPLE_SPLIT: 'Четверное',
};

export const AUTHORITIES: Record<string, string> = {
  SPLEEN: 'Селезеночный - быть в моменте',
  SACRAL: 'Сакральный - Откликайся жизни',
  SOLAR_PLEXUS: 'Эмоциональный - Жди ясности',
  EGO_PROJECTED: 'Самопроецируемый - Слушай себя, свой голос',
  EGO: 'Эго',
  NONE: 'Авторитет Окружения - Правильниое место и люди',
  MOON: 'Лунный',
  G_AUTHORITY: 'Джи',
};

export const STRATEGIES: Record<string, string> = {
  GENERATOR: 'Откликаться на предложенные возможности. Не инициировать',
  MANIFESTOR: 'Информировать. Предупреждать о своих действиях',
  PROJECTOR: 'Быть приглашенным',
  REFLECTOR: 'Наблюдение, отдых',
  MANIFEST_GENERATOR:
    'Пробовать на вкус, информировать прежде чем начать действовать, слушать внутренний отклик',
};

export const FALSEI: Record<string, string> = {
  GENERATOR:
    'Разочарование, фрустрация, отсутствие желания продолжать деятельность',
  MANIFESTOR: 'Гнев или злость',
  PROJECTOR: 'Горечь',
  REFLECTOR: 'Разочарование',
  MANIFEST_GENERATOR: 'Расстройство, злость',
};

export const CROSS: Record<string, string> = {
  JUXTAPOSED_10_15_18_17: 'Поведения (10/15|18/17)',
  JUXTAPOSED_3_50_41_31: 'Мутации (3/50 | 41/31)',
  JUXTAPOSED_23_43_30_29: 'Ассимиляции (23/43 | 30/29)',
  JUXTAPOSED_12_11_25_46: 'Артикуляции (12/11 | 25/46)',
  JUXTAPOSED_54_53_32_42: 'Амбиций (54/53|32/42)',
  JUXTAPOSED_37_40_5_35: 'Сделок (37/40|5/35)',
  JUXTAPOSED_27_28_19_33: 'Заботы (27/28|19/33)',
  JUXTAPOSED_42_32_60_56: 'Завершения (42/32 | 60/56)',
  JUXTAPOSED_55_59_9_16: 'Настроений (55/59 | 9/16)',
  LEFT_22_47_11_12: 'Информирования (22/47 | 11/12)',
  JUXTAPOSED_63_64_26_45: 'Сомнений (63/64 | 26/45)',
  JUXTAPOSED_40_37_35_5: 'Отрицания (40/37 | 35/5)',
  JUXTAPOSED_48_21_53_54: 'Глубины (48/21 | 53/54)',
  JUXTAPOSED_62_61_3_50: 'Деталей (62/61 | 3/50)',
  JUXTAPOSED_36_6_10_15: 'Кризиса (36/6 | 10/15)',
  JUXTAPOSED_17_18_38_39: 'Мнений (17/18 | 38/39)',
  JUXTAPOSED_24_44_13_7: 'Рационализации (24/44 | 13/7)',
  JUXTAPOSED_49_4_14_8: 'Принципов (49/4 | 14/8)',
  JUXTAPOSED_45_26_36_6: 'Обладания (45/26 | 36/6)',
  JUXTAPOSED_39_38_51_57: 'Провокации (39/38 | 51/57)',
  JUXTAPOSED_33_19_2_1: 'Отступления (33/19 | 2/1)',
  JUXTAPOSED_19_33_1_2: 'Потребности (19/33 | 1/2)',
  JUXTAPOSED_38_39_57_51: 'Оппозиции (38/39 | 57/51)',
  JUXTAPOSED_28_27_33_19: 'Рисков (28/27 | 33/19)',
  JUXTAPOSED_1_2_4_49: 'Самовыражения (1/2 | 4/49)',
  JUXTAPOSED_51_57_61_62: 'Шока (51/57 | 61/62)',
  JUXTAPOSED_52_58_21_48: 'Неподвижности (52/58 | 21/48)',
  JUXTAPOSED_56_60_27_28: 'Стимуляции (56/60 | 27/28)',
  JUXTAPOSED_59_55_16_9: 'Стратегии (59/55 | 16/9)',
  JUXTAPOSED_58_52_48_21: 'Жизненности (58/52 | 48/21)',
  JUXTAPOSED_30_29_34_20: 'Судеб (30/29 | 34/20)',
  JUXTAPOSED_61_62_50_3: 'Размышления (61/62 | 50/3)',
  JUXTAPOSED_26_45_6_36: 'Обманщика (26/45 | 6/36)',
  JUXTAPOSED_50_3_31_41: 'Ценностей (50/3 | 31/41)',
  JUXTAPOSED_34_20_40_37: 'Силы (34/20 | 40/37)',
  LEFT_3_50_41_31: 'Желаний (3/50 | 41/31)',
  JUXTAPOSED_21_48_54_53: 'Контроля (21/48 | 54/53)',
  JUXTAPOSED_2_1_49_4: 'Водителя (2/1 | 49/4)',
  JUXTAPOSED_14_8_59_55: 'Усиления (14/8 | 59/55)',
  JUXTAPOSED_35_5_22_47: 'Опыта (35/5 | 22/47)',
  JUXTAPOSED_16_9_63_64: 'Экспериментирования (16/9 | 63/64)',
  JUXTAPOSED_15_10_17_18: 'Крайностей (15/10 | 17/18)',
  JUXTAPOSED_41_31_44_24: 'Фантазии (41/31 | 44/24)',
  JUXTAPOSED_7_13_23_43: 'Взаимодействия (7/13 | 23/43)',
  JUXTAPOSED_5_35_47_22: 'Привычек (5/35 | 47/22)',
  JUXTAPOSED_60_56_28_27: 'Ограничения (60/56 | 28/27)',
  JUXTAPOSED_31_41_24_44: 'Влияния (31/41 | 24/44)',
  JUXTAPOSED_43_23_29_30: 'Озарения (43/23 | 29/30)',
  JUXTAPOSED_22_47_11_12: 'Грации (22/47 | 11/12)',
  JUXTAPOSED_11_12_46_25: 'Идей (11/12 | 46/25)',
  JUXTAPOSED_57_51_62_61: 'Интуиции (57/51 | 62/61)',
  JUXTAPOSED_25_46_58_52: 'Невинности (25/46 | 58/52)',
  JUXTAPOSED_64_63_45_26: 'Замешательства (64/63 | 45/26)',
  LEFT_51_57_61_62: 'Горна (51/57 | 61/62)',
  LEFT_28_27_33_19: 'Выравнивания (28/27 | 33/19)',
  LEFT_53_54_42_32: 'Циклов (53/54 | 42/32)',
  LEFT_43_23_29_30: 'Посвящения (43/23 | 29/30)',
  LEFT_23_43_30_29: 'Посвящения (23/43 | 30/29)',
  LEFT_26_45_6_36: 'Конфронтации (26/45 | 6/36)',
  LEFT_1_2_4_49: 'Неповиновения (1/2 | 4/49)',
  LEFT_58_52_48_21: 'Требований (58/52 | 48/21)',
  LEFT_30_29_34_20: 'Индустрии (30/29 | 34/20)',
  LEFT_21_48_54_53: 'Стремления (21/48 | 54/53)',
  LEFT_63_64_26_45: 'Господства (63/64 | 26/45)',
  LEFT_11_12_46_25: 'Образования (11/12 | 46/25)',
  LEFT_44_24_7_13: 'Инкарнации (44/24 | 7/13)',
  LEFT_29_30_20_34: 'Индустрии (29/30 | 20/34)',
  LEFT_27_28_19_33: 'Выравнивания (27/28 | 19/33)',
  LEFT_31_41_24_44: 'Альфы (31/41 | 24/44)',
  LEFT_2_1_49_4: 'Неповиновения (2/1 | 49/4)',
  LEFT_45_26_36_6: 'Конфронтации (45/26 | 36/6)',
  LEFT_52_58_21_48: 'Требований (52/58 | 21/48)',
  LEFT_54_53_32_42: 'Циклов (54/53 | 32/42)',
  JUXTAPOSED_9_16_64_63: 'Фокуса (9/16 | 64/63)',
  LEFT_12_11_25_46: 'Образования (12/11 | 25/46)',
  LEFT_41_31_44_24: 'Альфы (41/31 | 44/24)',
  LEFT_24_44_13_7: 'Инкарнации (24/44 | 13/7)',
  LEFT_48_21_53_54: 'Стремления (48/21 | 53/54)',
  LEFT_20_34_37_40: 'Дуальности (20/34 | 37/40)',
  JUXTAPOSED_8_14_55_59: 'Вклада (8/14 | 55/59)',
  LEFT_37_40_5_35: 'Миграции (37/40 | 5/35)',
  LEFT_38_39_57_51: 'Индивидуализма (38/39 | 57/51)',
  LEFT_62_61_3_50: 'Затмения (62/61 | 3/50)',
  LEFT_17_18_38_39: 'Бунта (17/18 | 38/39)',
  LEFT_33_19_2_1: 'Совершенствования (33/19 | 2/1)',
  LEFT_5_35_47_22: 'Разделения (5/35 | 47/22)',
  LEFT_50_3_31_41: 'Желаний (50/3 | 31/41)',
  LEFT_16_9_63_64: 'Идентификации (16/9 | 63/64)',
  LEFT_4_49_8_14: 'Революции (4/49 | 8/14)',
  LEFT_19_33_1_2: 'Совершенствования (19/33 | 1/2)',
  LEFT_55_59_9_16: 'Духа (55/59 | 9/16)',
  LEFT_8_14_55_59: 'Неуверенности (8/14 | 55/59)',
  RIGHT_4_49_23_43: 'Объяснения (4/49 | 23/43)',
  RIGHT_37_40_9_16: 'Планирования (37/40 | 9/16)',
  RIGHT_56_60_3_50: 'Законов (56/60 | 3/50)',
  RIGHT_16_9_37_40: 'Планирования (16/9 | 37/40)',
  RIGHT_60_56_50_3: 'Законов (60/56 | 50/3)',
  RIGHT_23_43_49_4: 'Объяснения (23/43 | 49/4)',
  RIGHT_43_23_4_49: 'Объяснения(43/23 | 4/49)',
  RIGHT_24_44_19_33: 'Четырех Путей (24/44 | 19/33)',
  RIGHT_44_24_33_19: 'Четырех Путей (44/24 | 33/19)',
  RIGHT_33_19_24_44: 'Четырех Путей (33/19 | 24/44)',
  RIGHT_3_50_60_56: 'Законов (3/50 | 60/56)',
  RIGHT_40_37_16_9: 'Планирования (40/37 | 16/9)',
  RIGHT_47_22_45_26: 'Управления (47/22 | 45/26)',
  RIGHT_9_16_40_37: 'Планирования (9/16 | 40/37)',
  RIGHT_17_18_58_52: 'Служения (17/18 | 58/52)',
  LEFT_7_13_23_43: 'Масок (7/13 | 23/43)',
  RIGHT_26_45_47_22: 'Управления (26/45 | 47/22)',
  RIGHT_45_26_22_47: 'Управления (45/26 | 22/47)',
  LEFT_40_37_35_5: 'Миграции (40/37 | 35/5)',
  LEFT_13_7_43_23: 'Масок (13/7 | 43/23)',
  RIGHT_49_4_43_23: 'Объяснения (49/4 | 43/23)',
  LEFT_10_15_18_17: 'Предупреждения (10/15 | 18/17)',
  LEFT_47_22_12_11: 'Информирования (47/22 | 12/11)',
  LEFT_36_6_10_15: 'Плана (36/6 | 10/15)',
  LEFT_9_16_64_63: 'Идентификации (9/16 | 64/63)',
  LEFT_15_10_17_18: 'Предупреждения (15/10 | 17/18)',
  LEFT_6_36_15_10: 'Плана (6/36 | 15/10)',
  LEFT_46_25_52_58: 'Целительства (46/25 | 52/58)',
  LEFT_49_4_14_8: 'Революции (49/4 | 14/8)',
  LEFT_59_55_16_9: 'Духа (59/55 | 16/9)',
  LEFT_18_17_39_38: 'Бунта (18/17 | 39/38)',
  LEFT_39_38_51_57: 'Индивидуализма (39/38 | 51/57)',
  LEFT_32_42_56_60: 'Ограничения (32/42 | 56/60)',
  LEFT_35_5_22_47: 'Разделения (35/5 | 22/47)',
  RIGHT_35_5_63_64: 'Сознания (35/5 | 63/64)',
  RIGHT_5_35_64_63: 'Сознания (5/35 | 64/63)',
  RIGHT_62_61_42_32: 'Майи (62/61 | 42/32)',
  RIGHT_61_62_32_42: 'Майи (61/62 | 32/42)',
  RIGHT_54_53_57_51: 'Проникновения (54/53 | 57/51)',
  RIGHT_58_52_18_17: 'Служения (58/52 | 18/17)',
  RIGHT_34_20_59_55: 'Спящего Феникса (34/20 | 59/55)',
  RIGHT_15_10_25_46: 'Сосуда Любви (15/10 | 25/46)',
  RIGHT_25_46_10_15: 'Сосуда Любви (25/46 | 10/15)',
  RIGHT_10_15_46_25: 'Сосуда Любви (10/15 | 46/25)',
  RIGHT_12_11_36_6: 'Эдема (12/11 | 36/6)',
  RIGHT_6_36_12_11: 'Эдема (6/36 | 12/11)',
  RIGHT_64_63_35_5: 'Сознания (64/63 | 35/5)',
  RIGHT_30_29_14_8: 'Инфицирования (30/29 | 14/8)',
  RIGHT_8_14_30_29: 'Инфицирования (8/14 | 30/29)',
  RIGHT_29_30_8_14: 'Инфицирования (29/30 | 8/14)',
  RIGHT_36_6_11_12: 'Эдема (36/6 | 11/12)',
  RIGHT_14_8_29_30: 'Инфицирования (14/8 | 29/30)',
  RIGHT_42_32_61_62: 'Майи (42/32 | 61/62)',
  RIGHT_57_51_53_54: 'Проникновения (57/51 | 53/54)',
  RIGHT_32_42_62_61: 'Майи (32/42 | 62/61)',
  RIGHT_53_54_51_57: 'Проникновения (53/54 | 51/57)',
  RIGHT_51_57_54_53: 'Проникновения (51/57 | 54/53)',
  RIGHT_20_34_55_59: 'Спящего Феникса (20/34 | 55/59)',
  RIGHT_18_17_52_58: 'Служения (18/17 | 52/58)',
  RIGHT_2_1_13_7: 'Сфинкса (2/1 | 13/7)',
  RIGHT_55_59_34_20: 'Спящего Феникса (55/59 | 34/20)',
  RIGHT_13_7_1_2: 'Сфинкса (13/7 | 1/2)',
  RIGHT_59_55_20_34: 'Спящего Феникса (59/55 | 20/34)',
  RIGHT_1_2_7_13: 'Сфинкса (1/2 | 7/13)',
  RIGHT_7_13_2_1: 'Сфинкса (7/13 | 2/1)',
  RIGHT_63_64_5_35: 'Сознания (63/64 | 5/35)',
  RIGHT_31_41_27_28: 'Неожиданного (31/41 | 27/28)',
  RIGHT_46_25_15_10: 'Сосуда Любви (46/25 | 15/10)',
  RIGHT_28_27_31_41: 'Неожиданного (28/27 | 31/41)',
  RIGHT_39_38_21_48: 'Напряжения (39/38 | 21/48)',
  RIGHT_21_48_38_39: 'Напряжения (21/48 | 38/39)',
  RIGHT_41_31_28_27: 'Неожиданного (41/31 | 28/27)',
  RIGHT_48_21_39_38: 'Напряжения (48/21 | 39/38)',
  LEFT_25_46_58_52: 'Целительства (25/46 | 58/52)',
  LEFT_42_32_60_56: 'Ограничения (42/32 | 60/56)',
  RIGHT_50_3_56_60: 'Законов (50/3 | 56/60)',
  JUXTAPOSED_20_34_37_40: 'Настоящего (20/34 | 37/40)',
  JUXTAPOSED_47_22_12_11: 'Угнетения (47/22 | 12/11)',
  JUXTAPOSED_46_25_52_58: 'Интуитивной Прозорливости (46/25 | 52/58)',
  JUXTAPOSED_4_49_8_14: 'Формулирования (4/49 | 8/14)',
  JUXTAPOSED_13_7_43_23: 'Слушания (13/7 | 43/23)',
  JUXTAPOSED_29_30_20_34: 'Соглашения (29/30 | 20/34)',
  JUXTAPOSED_53_54_42_32: 'Начинаний (53/54|42/32)',
  JUXTAPOSED_32_42_56_60: 'Сохранения (32/42 | 56/60)',
  JUXTAPOSED_44_24_7_13: 'Бдительности (44/24|7/13)',
  JUXTAPOSED_6_36_15_10: 'Конфликта (6/36 | 15/10)',
  JUXTAPOSED_18_17_39_38: 'Коррекции (18/17 | 39/38)',
  LEFT_57_51_62_61: 'Горна (57/51 | 62/61)',
  LEFT_34_20_40_37: 'Дуальности (34/20 | 40/37)',
  LEFT_60_56_28_27: 'Рассеянности (60/56 | 28/27)',
  LEFT_64_63_45_26: 'Господства (64/63 | 45/26)',
  LEFT_61_62_50_3: 'Затмения (61/62 | 50/3)',
  LEFT_14_8_59_55: 'Неуверенности (14/8 | 59/55)',
  LEFT_56_60_27_28: 'Рассеянности (56/60 | 27/28)',
  RIGHT_19_33_44_24: 'Четырех Путей (19/33 | 44/24)',
  RIGHT_22_47_26_45: 'Управления (22/47 | 26/45)',
  RIGHT_52_58_17_18: 'Служения (52/58 | 17/18)',
  RIGHT_11_12_6_36: 'Эдема (11/12 | 6/36)',
  RIGHT_38_39_48_21: 'Напряжения (38/39 | 48/21)',
  RIGHT_27_28_41_31: 'Неожиданного (27/28 | 41/31)',
};

export const COLUMN_NAMES = [
  'sun',
  'earth',
  'moon',
  'north_node',
  'south_node',
  'mercury',
  'venus',
  'mars',
  'jupiter',
  'saturn',
  'uranus',
  'neptune',
  'pluto',
];

export const CENTERS = [
  'HEAD',
  'AJNA',
  'THROAT',
  'G_CENTER',
  'HEART',
  'SPLEEN',
  'SACRAL',
  'SOLAR_PLEXUS',
  'ROOT',
];

export const CENTERS_DATA: Record<
  string,
  {
    title: string;
    description: string;
  }
> = {
  HEAD: {
    title: 'Центр вдохновения',
    description: 'Фантазии, идеи',
  },
  SOLAR_PLEXUS: {
    title: 'Центр эмоций',
    description: 'Настроение, страсть',
  },
  SACRAL: {
    title: 'Сакральный центр',
    description: 'Работоспособность, жизненная сила',
  },
  AJNA: {
    title: 'Аджина (центр ума)',
    description: 'Идеи, концепции, работа ума',
  },
  SPLEEN: {
    title: 'Центр селезенки',
    description: 'Вы можете доверять своей интуиции',
  },
  THROAT: {
    title: 'Горловой центр',
    description: 'Манифестация, самовыражение, действие',
  },
  HEART: {
    title: 'Сердечный центр',
    description: 'Не обещать, не пытаться доказать, что вы лучше',
  },
  ROOT: {
    title: 'Корневой центр',
    description: 'Адреналин, выживание, стрессоустойчивость',
  },
  G_CENTER: {
    title: 'G центр',
    description: 'Любовь, направление, самоидентичность',
  },
};

export const INACTIVE_COLOR = {
  fill: '#92BAFF',
};

export const ACTIVE_COLORS: Record<
  string,
  {
    fill: string;
  }
> = {
  HEAD: {
    fill: '#FFC000',
  },
  AJNA: {
    fill: '#28BC00',
  },
  THROAT: {
    fill: '#7E57C2',
  },
  G_CENTER: {
    fill: '#FFC000',
  },
  HEART: {
    fill: '#F44336',
  },
  SPLEEN: {
    fill: '#7E57C2',
  },
  SACRAL: {
    fill: '#F44336',
  },
  SOLAR_PLEXUS: {
    fill: '#7E57C2',
  },
  ROOT: {
    fill: '#7E57C2',
  },
};

export const ACTIVE_GATE = {
  rectColors: {
    fill: '#FFF',
    stroke: 'transparent',
    opacity: 1,
  },
  textColors: {
    fill: '#343B4C',
  },
};

export const INACTIVE_GATE = {
  rectColors: {
    fill: 'transparent',
    stroke: '#FFF',
    opacity: 0.5,
  },
  textColors: {
    fill: '#FFF',
  },
};

export const STROKE = {
  borderColor: '#E5EAEE',
  borderWidth: '4',
  colorWidth: '3',
};

export const CHANNELS_COLORS = {
  white: {
    bg: '#FFF',
    dash: '#FFF',
    border: 'transparent',
  },
  transparent: {
    bg: 'transparent',
    dash: 'transparent',
    border: 'transparent',
  },
  blue: {
    bg: '#315EFB',
    dash: 'transparent',
    border: 'transparent',
  },
  red: {
    bg: '#FF564E',
    dash: 'transparent',
    border: 'transparent',
  },
  striped: {
    bg: '#FF564E',
    dash: '#315EFB',
    border: STROKE.borderColor,
  },
};

export const CHANNELS_COUNT = 65;

export const SEO_URLS: Record<string, string> = {
  generator: 'generator',
  projector: 'proektor',
  manifestor: 'manifestor',
  manifest_generator: 'manifestiruyushhij-generator',
  reflector: 'reflektor',
};

export const HUMAN_DESIGN_PARTNER_TAGS = [
  'В работе',
  'В отношениях',
  'В детстве',
  'Высший смысл',
  'Питание',
  'Генетическая травма',
  'Среда обитания',
  'Призвание',
  'Ключи жизни',
];

export const HUMAN_DESIGN_PRICE = 1999;

export const HUMAN_DESIGN_STATIC_TEXT =
  'Дизайн человека (Human design) является уникальным инструментом, который показывает вам, какой вы есть на самом деле и в чем заключается ваша истинная индивидуальность. Простое понимание себя и своей внутренней природы позволит принимать правильные для вас решения.';

export const HUMAN_DESIGN_PARTNER_TEXT =
  'Вы узнали простое описание вашей карты. Если вы хотите получить полное описание бодиграфа и советы, как изменить свою жизнь, вы можете заказать расширенное прочтение. Расширенное прочтение включает в себя темы:';

export const HUMAN_DESIGN_PROFILES_STATIC_TEXT =
  'Профиль — это то, что определяет роль человека в жизни, его модель взаимодействия с окружающим миром, базовые черты характера. Ключ к пониманию профиля – это значение линии:';

export const HUMAN_DESIGN_TYPES_STATIC_TEXT =
  'Тип является одним из ключевых понятий дизайна человека. Каждый генетический тип имеет свою уникальную стратегию в жизни.';
